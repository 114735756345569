
import HARDCODE from '../../../business-const/HardCode/HardCode'

import {
    round
} from '../../../business-components/round/round'

import {
    pesquisaList,
} from '../../../business-rules/List/ListService'

import familiaresList from '../../../business-rules/List/familiaresList'



export const incrementaContConsorcio = ( values ) => {

    if (!values.contConsorcio) {

        values.contConsorcio = 0
    }

    values.contConsorcio = values.contConsorcio + 1
}

export const obtemContConsorcio = ( values ) => {

    let contConsorcio = ''

    if (!values.contConsorcio) {

         incrementaContConsorcio(values)

         contConsorcio = values.contConsorcio

    } else {

        contConsorcio = 1

        while (contConsorcio <= values.contConsorcio) {

            if (!pesquisaList(values.consorcio, contConsorcio).id) {
                break
            }

            contConsorcio++
        }

        if (contConsorcio > values.contConsorcio) {

            incrementaContConsorcio(values)

            contConsorcio = values.contConsorcio
        }  
    }

    return contConsorcio
}



export const processaRegistrosDefaultIniciaisConsorcio = ( values, familiar ) => {

    if (familiar) {

        if (!values.familiaresProcessouRegistrosDefaultIniciaisConsorcio) {

            values.familiaresProcessouRegistrosDefaultIniciaisConsorcio = []
        }

        if (!values.consorcio) {

            values.consorcio = []
        }
        
        if (
                !pesquisaList(values.familiaresProcessouRegistrosDefaultIniciaisConsorcio, familiar).id
        ) {

            values.familiaresProcessouRegistrosDefaultIniciaisConsorcio.push(
                {
                    id: familiar
                }
            )

            incrementaContConsorcio(values)

            values.consorcio.push(
                {
                    id: values.contConsorcio,
                    familiar: familiar,
                }
            )
        }
    }
}



export const processaValoresDefaultIniciaisConsorcio = ( item ) => {

    if (!item.dataAssembleia) {

        item.dataAssembleia = null
    }

    if (!item.dataVenctoBoleto) {

        item.dataVenctoBoleto = null
    }
}



export const dadosCompletosLinhaConsorcio = ( linhaConsorcio ) => {

    return linhaConsorcio.numeroConsorcio &&
        linhaConsorcio.administradoraConsorcio &&
        linhaConsorcio.tipoConsorcio &&
        linhaConsorcio.grupoConsorcio &&
        linhaConsorcio.cotaConsorcio &&
        linhaConsorcio.pessoaFisicaJuridica &&
        linhaConsorcio.statusGrupoConsorcio &&
        linhaConsorcio.dataAssembleia &&
        linhaConsorcio.numeroAssembleiaParticipacao &&
        linhaConsorcio.valorCreditoConsorcio &&
        linhaConsorcio.valorParcelaInicial &&
        linhaConsorcio.tipoFormaPagtoConsorcio &&
        linhaConsorcio.dataVenctoBoleto &&
        linhaConsorcio.prazoTotalGrupoConsorcio &&
        linhaConsorcio.numeroTotalParticipantesGrupoConsorcio &&
        linhaConsorcio.taxaAdministracaoTotal &&
        linhaConsorcio.percFundoReserva &&
        linhaConsorcio.percSeguroVida &&
        (linhaConsorcio.percLanceEmbutido || linhaConsorcio.percLanceEmbutido === 0) &&
        linhaConsorcio.percLanceFixo
}

export const calculaTotaisConsorcio = ( values ) => {

    values.totalConsorcio = []

    let valorTotalParcelaInicialTotal = 0

    familiaresList(values)?.forEach( (linhaFamiliaresList, index) => {

        valorTotalParcelaInicialTotal = 0

        values.consorcio?.forEach( (linhaConsorcio, index) => {

            if (
                    linhaConsorcio.familiar === linhaFamiliaresList.id &&
                    dadosCompletosLinhaConsorcio(linhaConsorcio)
            ) {

                valorTotalParcelaInicialTotal += round(linhaConsorcio.valorParcelaInicial, 2)
            }
        })

        if (
                valorTotalParcelaInicialTotal !== 0 ||
                linhaFamiliaresList.id === HARDCODE.idFamiliarCliente ||
                linhaFamiliaresList.id === HARDCODE.idFamiliarConjuge
        ) {

            values.totalConsorcio.push(
                {
                    id: linhaFamiliaresList.id,
                    descricao: linhaFamiliaresList.descricao,
                    valor: valorTotalParcelaInicialTotal,
                }
            )
        }
    })
}