import AuthService from '../../business-api/AuthService'

import HARDCODE from '../HardCode/HardCode'

import {
    moedaPrefixSuffix
 } from '../../business-components/Formata/Formata'

const { prefix, suffix } = moedaPrefixSuffix()

const tipoIdioma = AuthService.obterTipoIdioma()

const aposentadoria = tipoIdioma === HARDCODE.tipoIdiomaPtPt ? 'Reforma' : 'Aposentadoria'



const LABEL = {

    a: ' a ',
    e: ' e ',
    ou: ' ou ',
    de: 'de',
    ate: ' até ',
    virgula: ',',
    acimaDe: 'acima de ',
    traco: ' - ',
    tracoXtraco: '-x-',
    espaco: '&nbsp',



    naoInformar: 'Não informar',



    login: 'Login',
    cadastreSe: 'Cadastre-se',
    esqueceuSenha: 'Esqueceu a senha?',
    email: 'E-mail',
    senha: 'Senha',
    repetirSenha: 'Repetir Senha',
    pin: 'Informe o PIN',
    linkEnviarPinEmail: 'Enviar PIN por E-mail',
    linkReenviarPinEmail: 'Reenviar PIN por E-mail',



    home: 'Home',
    configuracao: 'Configuração',
    sair: 'Sair',



    dashboard: 'Dashboard',
    dashboardCliente: 'Dashboard: Clientes',
    agenda: 'Agenda',
    cliqueParaVisualizarAtividadesAgenda: 'Clique para visualizar os clientes com atividades agendadas',
    aniversario: 'Aniversário',
    cliqueParaVisualizarAniversariantes: 'Clique para visualizar os clientes e familiares aniversariantes',

    dashboardFamilia: 'Dashboard: Familiares',

    dashboardConsorcio: 'Dashboard: Consórcios',
    consorcios: 'Consórcios',
    consorciosPorMesAssembleia: 'Consórcios p/ Mês Assembleia',
    consorciosPorMesVenctoBoleto: 'Consórcios p/ Mês Vencto.Boleto',
    consorciosPorAdministradoraConsorcio: 'Consórcios por Administradora',
    consorciosPorTipoConsorcio: 'Consórcios por Tipo',
    consorciosPorStatusGrupoConsorcio: 'Consórcios por Status Grupo',
    consorciosPorFormaPagtoConsorcio: 'Consórcios por Forma Pagto.',
    consorciosPorCidade: 'Consórcios por Cidade',

    dashboardProjetoVidaSonho: 'Dashboard: Projetos de Vida/Sonho',

    dashboardProposta: 'Dashboard: Propostas',
    dataAssinaturaInicial: 'Data Assinatura Inicial',
    dataAssinaturaFinal: 'Data Assinatura Final',
    dataUltimaAlteracaoInicial: 'Data Ultima Alteração Inicial',
    dataUltimaAlteracaoFinal: 'Data Ultima Alteração Final',
    propostasPorMes: 'Propostas por Mês',
    propostasPorGestorLider: 'Propostas por Gestor/Líder',
    propostasPorUsuario: 'Propostas por Usuário',
    propostasPorSeguradora: 'Propostas por Seguradora',
    propostasPorPeriodicidadePagto: 'Prop. por Periodic. Pagto.',
    propostasPorFormaPagto: 'Propostas por Forma Pagto.',
    propostasPorStatus: 'Propostas por Status',

    dashboardApolice: 'Dashboard: Apólices',
    dataEmissaoInicial: 'Data Emissão Inicial',
    dataEmissaoFinal: 'Data Emissão Final',
    apolicesEmitidasPorMes: 'Emissões por Mês',
    apolicesEmitidasPorGestorLider: 'Emissões por Gestor/Líder',
    apolicesEmitidasPorUsuario: 'Emissões por Usuário',
    apolicesEmitidasPorSeguradora: 'Emissões por Seguradora',
    apolicesEmitidasPorProfissao: 'Emissões por Profissao',
    apolicesEmitidasPorPeriodicidadePagto: 'Emissões por Period.Pagto.',
    apolicesEmitidasPorCidade: 'Emissões por Cidade',
    apolicesEmitidasPorSexo: 'Emissões por Sexo',
    apolicesEmitidasPorStatusAtual: 'Emissões por Status Atual',
    apolicesEmitidasPorParentesco: 'Emissões por Parentesco',

    dashboardApoliceCobranca: 'Dashboard: Cobrança',
    parcelas: 'Parcelas',
    parcelasPagas: 'Parcelas Pagas',
    parcelasEmAberto: 'Parcelas em Aberto',
    parcelasPorGestorLider: 'Parcelas por Gestor/Líder',
    parcelasPorUsuario: 'Parcelas por Usuário',
    parcelasPorSeguradora: 'Parcelas por Seguradora',
    parcelasPorBanco: 'Parcelas por Banco',
    parcelasPorDiaVencimento: 'Parcelas por Dia de Vencto.',
    parcelasPorStatus: 'Parcelas por Status',
    parcelasPorPeriodicidadePagto: 'Parcelas por Periodic. Pagto.',

    dashboardApoliceComissao: 'Dashboard: Comissão',
    comissoesPorMes: 'Comissões por Mês',
    comissoesPorGestorLider: 'Comissões por Gestor/Líder',
    comissoesPorUsuario: 'Comissões por Usuário',
    comissoesPorSeguradora: 'Comissões por Seguradora',
    comissoesPorPeriodicidadePagto: 'Comissões por Period.Pagto.',
    comissoesPorFormaPagto: 'Comissões por Forma Pagto.',
    

    
    dashboardClienteAgente: 'Dashboard: Clientes x Agentes',
    clientesAgentes: 'Clientes x Agentes',
    clientesAgentesPorGestorLider: 'Clientes por Gestor/Líder',
    clientesAgentesPorUsuario: 'Clientes por Usuário',
    clientesAgentesPorInstituicaoFinanceira: 'Clientes por Inst.Financeira',
    clientesAgentesPorStatus: 'Clientes por Status',


    dashboardAporte: 'Dashboard: Aportes',
    aporte: 'Aporte',
    aportes: 'Aportes',
    cliqueParaVisualizarAportesPrevistos: 'Clique para visualizar os clientes com aportes previstos',
    dataInicial: 'Data Inicial',
    dataFinal: 'Data Final',
    aportesTotalPeriodo: 'Aportes: Total do Período',
    aportesDiarios: 'Aportes Diários',
    cliqueParaEditarAportesDia: 'Clique para editar os aportes do dia',
    previsto: 'Previsto',
    realizado: 'Realizado',
    pendente: 'Pendente',
    clientes: 'Clientes',

    pageAgenda: 'Agenda',
    pageClienteList: 'Clientes',
    pageAporteList: 'Aportes',
    pageClienteFormNovoCliente: 'Novo Cliente',
    pageClienteFormEditarCliente: 'Editar Cliente',
    pageImportarClienteExcelForm: 'Importar um arquivo Excel',
    pageIntegracaoClienteArquivoList: 'Integração',
    pageFintracks: 'Fin-tracks',
    pageConfiguracao: 'Configuração',



    totalClientes: 'Clientes<br/><br/><br/><br/>',
    totalPropostasSeguroVidaMapaPropostas: 'Seguro de Vida<br/><u>Mapa de Propostas</u><br/><br/><b>PA</b> = <b>$valor$<b>',
    totalPropostasSeguroVidaPendentesMesAtual2Meses: 'Seguro de Vida: Propostas<br/><u>Pendentes Mês Atual + 2 Meses</u><br/><br/><b>PA</b> = <b>$valor$<b>',
    totalPropostasSeguroVidaPagasMesAtual: 'Seguro de Vida: Propostas<br/><u>Pagas Mês Atual</u><br/><br/><b>PA</b> = <b>$valor$<b>', // mudança da fonte: Pagto
    receitaMensalMaiorIgual10000: 'Receita Mensal<br/>>= ' + prefix + '10K' + suffix + '<br/><br/><br/>',
    patrimonioImobilizadoMaiorIgual1000000: 'Patrimônio Imobilizado<br/>>= ' + prefix + '1M' + suffix + '<br/><br/><br/>',
    recursosFinanceirosMaiorIgual1000000: 'Recursos Financeiros<br/>>= ' + prefix + '1M' + suffix + '<br/><br/><br/>',
    cliqueParaEditarCliente: 'Clique para editar o cliente',
    qtdeFilhos: 'Filhos',
    receitaMensalClienteConjuge: 'Receita Mensal',
    patrimonioImobilizadoClienteConjuge: 'Patrimônio Imobilizado',
    recursosFinanceirosClienteConjuge: 'Recursos Financeiros',
    funilEtapa: 'Funil/Etapa',


    
    pesquisa: 'Pesquisa',
    filtro: 'Filtro',



    usuarioDestino: 'Usuário Destino',



    nomeArquivo: 'Nome Arquivo',
    tipoArquivo: 'Tipo Arquivo',
    statusProcessamento: 'Status Processamento',
    registrosProcessados: 'Registros Processados',
    errosProcessamento: 'Erros Processamento',
    dataHoraStatusProcessamento: 'Data/Hora Status Processamento',
    arquivo: 'Arquivo',
    arquivos: 'Arquivos',


    apolices: 'Apólices',
    pageApoliceList: 'Apólices',
    nomeSegurado: 'Nome do Segurado',
    coberturaBasica: 'Cobertura Básica',
    inicioVigencia: 'Início Vigência',
    dataCancelamento: 'Data Cancelamento',
    motivoCancelamento: 'Motivo Cancelamento',
    periodicidadePagamento: 'Periodicidade Pagamento',
    capitalSegurado: 'Capital Segurado',
    premio: 'Prêmio',
    status: 'Status',
    idCorretorSeguros: 'Corretor de Seguros',
    codigoAgenteCorretorSeguros: 'Código Corretor Seguros',
    percDivisaoComissao: '% Divisão Comissão',
    idCorretorSeguros2: 'Corretor Seguros 2',
    codigoAgenteCorretorSeguros2: 'Código Corretor Seguros 2',
    percDivisaoComissao2: '% Divisão Comissão 2',
    codigoAgenteAssessorInvestimentos: 'Código Assessor de Investimentos',



    propostas: 'Propostas',



    cobrancas: 'Cobranças',



    comissoes: 'Comissões',



    pageVideoList: 'Vídeos',
    selecioneVideoDesejado: 'Selecione o Vídeo Desejado',
    funcionalidade: 'Funcionalidade',
    casoNegocio: 'Caso de Negócio',
    video: 'Vídeo',
    trilhaConhecimento: 'Trilha do Conhecimento',



    treinamentos: 'Treinamentos',
    pageTreinamentoList: 'Treinamentos',
    data: 'Data',
    horario: 'Horário',
    linkIncricao: 'Link Inscrição',



    gerarPDF: 'Gerar PDF',
    gerarPDFGrid: 'Gerar a Grid em PDF',
    exportarDadosExcel: 'Exportar Dados para Excel',
    configurarCamposRelatorio: 'Configurar os Campos do Relatório',
    gerarExtratoApolice: 'Gerar o Extrato da Apólice em PDF',
    extratoApolice: 'Extrato da Apólice',



    pageUsuarioEstatistica: 'Estatísticas Usuários',
    usuarioEstatistica: 'Estatísticas Usuários',
    dataInicioFreetrack: 'Data Início Free-track',
    turma: 'Turma',
    qtdeClientes: 'Clientes',
    qtdeTransacoes: 'Transações',
    qtdeTransacoes30dias: '30 dias',
    qtdeTransacoes15dias: '15 dias',
    qtdeTransacoes7dias: '7 dias',
    qtdeTransacoes3dias: '3 dias',
    qtdeTransacoes2dias: '2 dias',
    qtdeTransacoes1dia: '1 dia',
    qtdeTransacoesHoje: 'Hoje',
    dataInicioCobranca: 'Data Início Cobrança',
    dataUltimaCobrancaAte: 'Data Última Cobrança Até',
    dataPrecoModuloBaseAtual: 'Data Preço Módulo Base Atual',
    precoModuloBaseAtual: 'Preço Módulo Base Atual',



    pageEquipeList: 'Equipe',

    razaoSocial: 'Razão Social',
    nomeFantasia: 'Nome Fantasia',
    cnpj: 'CNPJ',
    inscricaoEstadual: 'Inscrição Estadual',
    numeroSusep: 'SUSEP',
    site: 'Site',
    linkLogoTopbar: 'Link Logo Top Bar',
    possuiMultiplasMarcas: 'Possui múltiplas marcas',
    grupo: 'Grupo',	
    subgrupo: 'Subgrupo',	

    tokenIntegracao: 'Token Integração',
    token1: 'Token 1',
    token2: 'Token 2',

    usuario: 'Usuário',
    nenhumUsuarioFoiSelecionado: 'Nenhum usuário foi selecionado',
    equipe: 'Equipe',

    perfil: 'Perfil',
    tipoLicenca: 'Tipo de Licença',
    gestorLider: 'Gestor / Líder',
    whiteLabel: 'White Label',
    ativo: 'Ativo',
    nome: 'Nome',
    telefone: 'Telefone',
    celular: 'Celular',
    profissaoQualificacao: 'Profissão/Qualificação',
    codigoAgente: 'Código Agente',
    utilizarEstudoCompleto: 'Utilizar Estudo Completo',
    utilizarSimulacaoFinanceiraEstudo: 'Utilizar Simulação Financeira no Estudo',

    numeroConta: 'Número Conta',
    numeroContaCliente: 'Número Conta',

    integracaoSeguradoras: 'Integração Seguradoras',

    statusIntegracao: 'Status Integração',

    desejaAssinar: 'Deseja Assinar o Fin-tracks ?',
    freeTrack: 'Free-track',
    realizarAssinatura: 'Realizar Assinatura do Fin-tracks',
    alterarCartaoCredito: 'Alterar Cartão de Crédito',
    dataAssinaturaModuloBase: 'Data da Assinatura do Módulo Base',
    precoModuloBase: 'Preço do Módulo Base',
    precoValidoAte: 'Preço válido para Assinaturas até',
    descontoVolumeAssinaturas: 'Desconto por Volume de Assinaturas',
    assinaturaVigente: 'Assinatura Vigente',
    pagamentoBoleto: 'Pagamento com Boleto',
    cartaoCreditoAtivo: 'Cartão de Crédito Ativo',
    nomeCartaoCredito: 'Nome Impresso no Cartão',
    cpfCartaoCredito: 'CPF Titular Cartão',
    cancelarAssinatura: 'Cancelar Assinatura',
    confirmaCancelamentoAssinatura: 'Confirma o Cancelamento da Assinatura ?',

    importarClienteExcelFormErro: 'Cadastramento não realizado.',
    importarClienteExcelFormOk: 'Cadastramento concluído',

    filialUnidade: 'Filial/Unidade',

    funil: 'Funil',
    etapa: 'Etapa',
    limpouFunil: 'Limpou o Funil',
    informarDataHoraPrevista: 'Informar Data/Hora Prevista',
    informarDataRealizacao: 'Informar Data Realização',
    exibirPotencialNegocios: 'Exibir Potencial Negócios',
    ordem: 'Ordem',
    dataHoraPrevista: 'Data/Hora Prevista',
    dataRealizacao: 'Data Realização',
    potencialNegocios: 'Potencial Negócios',

    logos: 'Logos',
    capaRelatorio: 'Capa Relatório',
    paginasRelatorio: 'Páginas Relatório',
    alturaLogoTopbar: 'Altura Logo Top Bar',
    larguraLogoTopbar: 'Largura Logo Top Bar',
    exibirLogoTopbarParceiros: 'Exibir a Logo na Top Bar dos Parceiros',
    naoExibirLogoCapaRelatorio: 'Não exibir a Logo na Capa do Relatório',
    alinhamentoLogoCapaRelatorio: 'Alinhamento da Logo na Capa do Relatório',
    alturaLogoReport: 'Altura Logo Relatório',
    larguraLogoReport: 'Largura Logo Relatório',
    backgroundCapaRelatorioPaginaInteira: 'Utilizar a página inteira',
    backgroundPaginaRelatorioNaoExibirLogoCabecalho: 'Não exibir logo no cabeçalho',
    backgroundPaginaRelatorioPaisagemNaoExibirLogoCabecalho: 'Não exibir logo no cabeçalho',
    backgroundPaginaRelatorioNaoExibirLinhaDivisoriaCabecalho: 'Não exibir linha divisória do cabeçalho',
    backgroundPaginaRelatorioPaisagemNaoExibirLinhaDivisoriaCabecalho: 'Não exibir linha divisória do cabeçalho',

    naoExibirAvatarClienteConjuge: 'Não exibir o Avatar do Cliente e Cônjuge',

    cor: 'Cor',
    corPadrao1: 'Cor Padrão 1: Fundo da Tela de Login e da Ferramenta',
    corPadrao2: 'Cor Padrão 2: Login, Menu Lateral, Painéis de Seleção',
    corPadrao3: 'Cor Padrão 3: Botões, Menu de Seleção Horizontal', 
    corPadrao4: 'Cor Padrão 4: Texto do Menu Lateral', 
    corPadrao5: 'Cor Padrão 5: Texto do Cabeçalho do Relatório', 

    calculos: 'Cálculos',
    mesesSugestaoDoencasGraves: 'Meses Sugestão Doenças Graves',
    tipoCalculoSugestaoDoencasGraves: 'Tipo Cálculo Sugestão Doenças Graves',
    tituloSubTotal1InputDiaDia: 'Título Subtotal 1 do Input Dia a Dia (Default: Cartão de Crédito)',
    tituloSubTotal2InputDiaDia: 'Título Subtotal 2 do Input Dia a Dia (Default: Débito/Dinheiro)',
    //considerarPrevidenciaPrivadaInventario: 'Considerar Previdência Privada no cálculo do Inventário',
    habilitarDivisaoComissaoSeguroVida: 'Habilitar a Divisão de Comissão no Seguro de Vida',

    textos: 'Textos',
    textoMorte: 'Texto Morte',
    estudoReportPrimeiraReuniaoTextoAbertura: 'Texto Relatório - Abertura 1ª Reunião',

    estudoReportCapa: 'Capa',

    estudoReportCapaTitulo1: 'Texto Relatório - Capa - Título 1 (Default: Planejamento Financeiro de Padrão de Vida)',
    estudoReportCapaTitulo1MargemTopo: 'Margem Topo Título 1 na Capa',
    estudoReportCapaNaoExibirTitulo1: 'Não exibir Título 1 na Capa',
    estudoReportCapaNaoExibirUsuario: 'Não exibir Usuário na Capa',
    estudoReportCapaNaoExibirProfissaoQualificacao: 'Não exibir Profissão/Qualificação na Capa',
    estudoReportCapaTitulo2: 'Texto Relatório - Capa - Título 2 (Default: a sua trilha financeira)',
    estudoReportCapaClienteConjugeTitulo2MargemTopo: 'Margem Topo Cliente/Cônjuge e Título 2 na Capa',
    estudoReportCapaClienteConjugeTitulo2MargemEsquerda: 'Margem Esquerda Cliente/Cônjuge e Título 2 na Capa',
    estudoReportCapaNaoExibirClienteConjuge: 'Não exibir Cliente/Cônjuge na Capa',
    estudoReportCapaNaoExibirTitulo2: 'Não exibir Título 2 na Capa',
    //estudoReportCapaExibirTextoCorBranca: 'Exibir textos da Capa na cor Branca',

    estudoReportOQueEsperarDesseTrabalho: 'Texto Relatório - O que esperar desse Trabalho?',
    
    estudoReportDetalhamentoDiaDiaDespesasVitaliciasTextoCabecalho: 'Texto Relatório - Cabeçalho Detalhamento Dia a Dia - Despesas Vitalícias',
    estudoReportDetalhamentoEducacaoTextoCabecalho: 'Texto Relatório - Cabeçalho Detalhamento Educação',
    estudoReportDetalhamentoFinanciamentoTextoCabecalho: 'Texto Relatório - Cabeçalho Detalhamento Financiamento',
    estudoReportDetalhamentoDiaDiaDespesasTemporariasTextoCabecalho: 'Texto Relatório - Cabeçalho Detalhamento Dia a Dia - Despesas Temporárias',
    
    estudoReportDetalhamentoPatrimonioImobilizadoTextoCabecalho: 'Texto Relatório - Cabeçalho Detalhamento Patrimônio Imobilizado',
    estudoReportDetalhamentoReceitaMensalTextoCabecalho: 'Texto Relatório - Cabeçalho Detalhamento Receita Mensal',
    estudoReportDetalhamentoRecursosFinanceirosTextoCabecalho: 'Texto Relatório - Cabeçalho Detalhamento Recursos Financeiros',
    
    estudoReportDisclaimer: 'Texto Relatório - Disclaimer',

    tiposPendencias: 'Tipos de Pendências',
    tipoPendencia: 'Tipo de Pendência',
    qtdePendenciasEmAberto: 'Pendências',
    pendencias: 'Pendências',
    totalPendencias: 'Clientes com Pendências',

    tituloAnotacao: 'Título Anotação',
    dataAnotacao: 'Data Anotação',

    enviarLembreteAporte: 'Enviar Lembrete Aporte',
    diasAntecedenciaEnvioLembreteAporte: 'Dias Antecedência Envio Lembrete Aporte',
    textoDescricaoAporteEnvioLembreteAporte: 'Texto Descrição Aporte',

    valorAportePrevisto: 'Valor Aporte Previsto',
    dataPrevistaAporte: 'Data Prevista Aporte',
    valorAporteRealizado: 'Valor Aporte Realizado',
    dataRealizacaoAporte: 'Data Realização Aporte',
    statusAporte: 'Status Aporte',
    exibirStatusAportePendente: 'Exibir Aportes Pendentes',
    exibirStatusAporteNaoRealizado: 'Exibir Aportes Não Realizados',
    exibirStatusAporteRealizado: 'Exibir Aportes Realizados',

    compartilhamento: 'Compartilhamento',
    habilitaCompartilhamentoClientes: 'Habilitar o Compartilhamento de Clientes',

    naoPermitirInclusaoNovosClientes: 'Não permitir a inclusão de novos clientes pela Fin-Tracks',
    naoPermitirAlterarNomeCliente: 'Não permitir alterar o nome do cliente pela Fin-Tracks',
    naoPermitirAlterarCpfCliente: 'Não permitir alterar o CPF do cliente pela Fin-Tracks',

    pageParceiroList: 'Parceiros',

    globalizacao: 'Globalização',
    tipoIdioma: 'Tipo Idioma',
    tipoMoeda: 'Tipo Moeda',

    parceiro: 'Parceiro',

    default: 'Default',
    compartilhar: 'Compartihar',
    formulario: 'Formulário',
    configuracaoPerguntasFormulario: 'Configuração das Perguntas do Formulário',
    relatorio: 'Relatório',
    previewRelatorio: 'Preview do Relatório',
    template: 'Template',
    configuracaoRelatorioTemplate: 'Configuração do Template de Relatório',
    padrao: 'Padrão',

    configuracaoCenariosCenario: 'Configuração do Cenário',

    pagina: 'Página',
    tituloPagina: 'Título da Página',
    paginaInteira: 'Página Inteira',
    capa: 'Capa',
    configuracaoPagina: 'Configuração da Página',
    tipoItemPagina: 'Tipo',
    margemTop: 'Margem Topo',
    margemLeft: 'Margem Esquerda',
    html: 'HTML',
    graficoTabela: 'Gráfico / Tabela',
    copia: 'Cópia',



    moverParaBaixo: 'Mover para baixo',
    moverParaCima: 'Mover para cima',
    
    

    tabConfiguracaoFormListDadosCadastrais: 'Dados Cadastrais',
    tabConfiguracaoFormListDadosPessoais: 'Dados Pessoais',
    tabConfiguracaoFormListEquipe: 'Equipe',
    tabConfiguracaoFormListAssinatura: 'Assinatura',
    tabConfiguracaoFormListParametros: 'Parâmetros',
    tabConfiguracaoFormListParceiros: 'Parceiros',
    tabConfiguracaoFormListPerguntas: 'Formulários',
    tabConfiguracaoFormListCenarios: 'Cenários',
    tabConfiguracaoFormListRelatorio: 'Templates',
    tabConfiguracaoFormListPagina: 'Páginas',

    tabConfiguracaoFormEquipeListEquipe: 'Equipe',
    tabConfiguracaoFormEquipeListFiliaisUnidades: 'Filiais/Unidades',
    tabConfiguracaoFormEquipeDashboardClienteAgente: 'Clientes x Agentes',

    tabConfiguracaoFormParametrosListFunis: 'Funis',
    tabConfiguracaoFormParametrosListImagemLogosCapaPaginas: 'Logos/Capa/Páginas',
    tabConfiguracaoFormParametrosListCores: 'Cores',
    tabConfiguracaoFormParametrosListParametrosCalculos: 'Cálculos',
    tabConfiguracaoFormParametrosListParametrosTextos: 'Textos',
    tabConfiguracaoFormParametrosListParametrosTipoPendencia: 'Tipos de Pendências',
    tabConfiguracaoFormParametrosListParametrosCompartilhamento: 'Compartilhamento',
    tabConfiguracaoFormParametrosListParametrosPDF: 'PDF',
    tabConfiguracaoFormParametrosListParametrosIntegracao: 'Integração',
    tabConfiguracaoFormParametrosListParametrosAporte: 'Aportes',
    tabConfiguracaoFormParametrosListParametrosGlobalizacao: 'Globalização',
    tabConfiguracaoFormParametrosListGestaoPatrimonial: 'Gestão Patrimonial',


    tabDashboardGraficos: 'Gráficos',
    tabDashboardRelatorios: 'Relatórios',


    tabClienteFormListDadosBasicos: 'Dados Básicos',
    tabClienteFormListSimulacaoFinanceira: 'Simulação Financeira',

    tabClienteFormListCadastro: 'Cadastro',
    tabClienteFormListFluxoCaixa: 'Fluxo de Caixa',
    tabClienteFormListPatrimonio: 'Patrimônio',
    tabClienteFormListEstudo: 'Estudo',
    tabClienteFormListGestaoPatrimonial: 'Gestão Patrimonial',
    tabClienteFormListConsideracoesFinais: 'Considerações Finais',
    tabClienteFormListPlanoAcao: 'Plano de Ação',
    tabClienteFormListRelatorio: 'Relatório',
    tabClienteFormListAcompanhamento: 'Acompanhamento',
    tabClienteFormListPrevidenciaPrivada: 'Previdência Privada',


    tabSimulacaoFinanceiraSimulacao: 'Simulação',
    tabSimulacaoFinanceiraMapaFinanceiro: 'Mapa Financeiro',
    tabSimulacaoFinanceiraReportPrimeiraReuniaoCliente: 'Relatorio',
    tabSimulacaoFinanceiraReportPrimeiraReuniaoInvestimento: 'Investimentos',
    tabSimulacaoFinanceiraReportPrimeiraReuniaoSeguroVida: 'Seguro de Vida',


    tabAcompanhamentoFormListPendencias: 'Pendências',
    tabAcompanhamentoFormListFunisEtapas: 'Funis / Etapas',
    tabAcompanhamentoFormListAnotacoes: 'Anotações',
    tabAcompanhamentoFormListAportes: 'Aportes',
    tabAcompanhamentoFormListConsorcio: 'Consórcio',
    tabAcompanhamentoFormListSeguroVida: 'Seguro de Vida',
    tabAcompanhamentoFormListArquivo: 'Arquivo',


    tabApoliceFormFiltrosCamposListFiltros: 'Filtro',
    tabApoliceFormFiltrosCamposListCampos: 'Campos',

    tabApoliceFormDadosListLista: 'Lista',
    tabApoliceFormDadosListDashboard: 'Dashboard',
    tabApoliceFormDadosListExtratoApolice: 'Extrato da Apólice',

    tabApoliceFormExtratoApoliceListApolice: 'Apólice',
    tabApoliceFormExtratoApoliceListProposta: 'Proposta',
    tabApoliceFormExtratoApoliceListSegurado: 'Segurado',
    tabApoliceFormExtratoApoliceListResponsávelPagamento: 'Resp.Pagamento',	
    tabApoliceFormExtratoApoliceListCoberturas: 'Coberturas',
    tabApoliceFormExtratoApoliceListAssistências: 'Assistências',
    tabApoliceFormExtratoApoliceListDadosPagamento: 'Dados Pagto.',
    tabApoliceFormExtratoApoliceListPagamentosEmAtraso: 'Pagtos. Atraso',
    tabApoliceFormExtratoApoliceListHistoricoPagamentos: 'Histórico Pagtos.',
    tabApoliceFormExtratoApoliceListBeneficiarios: 'Beneficiários',
    tabApoliceFormExtratoApoliceListDocumentos: 'Documentos',
    tabApoliceFormExtratoApoliceListEndossos: 'Endossos',
    tabApoliceFormExtratoApoliceListAreaComercial: 'Área Comercial',



    premioAnualizado: 'Prêmio Anualizado',



    modoInteracaoCliente: 'Ativar modo coleta de dados / apresentação',

    emailUsuarioOwnerCliente: 'E-mail Usuário Owner Cliente',
    cliente: 'Cliente',
    nomeCliente: 'Cliente',
    conjuge: 'Cônjuge',
    nomeConjuge: 'Cônjuge',
    nomeCurto: 'Nome Curto',
    cpf: 'CPF',
    cpfCliente: 'CPF Cliente',
    idClienteIntegracaoCRM: 'Id Integração CRM',
    cpfConjuge: 'CPF Cônjuge',
    nascimento: 'Nascimento',
    nascimentoCliente: 'Nascimento Cliente',
    nascimentoConjuge: 'Nascimento Cônjuge',
    diaMesNascimento: 'Dia/Mês Nascimento',
    idade: 'Idade',
    sexo: 'Sexo',
    tipoOrigemCliente: 'Origem do Cliente',
    clienteRecomendou: 'Recomendado por',
    tipoOrigemClienteOutras: 'Origem do Cliente - Outras',
    tipoCarteiraInvestimentoCliente: 'Tipo da Carteira de Investimento',
    momentoCliente: 'Momento do Cliente',
    //observacaoCliente: 'Observação (Rapport)',
    grauInstrucao: 'Grau Instrução',
    formacao: 'Formação',
    profissao: 'Profissão',
    profissaoOutras: 'Profissão - Outras',
    empresa: 'Empresa',
    possuiConjuge: 'Possui Cônjuge',
    tipoRegimeBens: 'Regime de Bens',
    percMeacao: '% Meação',
    percHerancaFamiliarDiferenteConjuge: '% Herança do Cliente é diferente do Cônjuge',
    celularCliente: 'Celular Cliente',
    celularConjuge: 'Celular Cônjuge',

    selecionarImagem: 'Selecionar Imagem',

    disponibilizarInclusaoRevisaoDadosBasicos: 'Disponibilizar a inclusão ou revisão dos Dados Básicos',
    disponibilizarRelatorioSimulacaoFinanceira: 'Disponibilizar o Relatório da Simulação Financeira',
    disponibilizarRelatorioEstudo: 'Disponibilizar o Relatório do Estudo',
    disponibilizarAcompanhamento: 'Disponibilizar o Acompanhamento',
    dataHoraValidadeLink: 'Data/Hora Validade Link',
    dataHoraValidadeMaxima: 'A data/hora de validade máxima permitida para o Link do Cliente é $1$',
    gerandoLinkCliente: 'Gerando o link para o cliente ...',
    linkCliente: 'Link para o Cliente',

    casal: 'CASAL',

    dataHoraInclusao: 'Data/Hora Inclusão',
    dataInclusao: 'Data Inclusão',
    dataHoraAlteracao: 'Data/Hora Alteração',

    familiares: 'Familiares',
    parentesco: 'Parentesco',
    percHeranca: '% Herança',
    percHerancaCliente: '% Herança Cliente',
    percHerancaConjuge: '% Herança Cônjuge',

    estruturaFamiliar: 'Estrutura Familiar',
    meacao: 'Meação',
    heranca: 'Herança',
    direitoSucessorio: 'Direito Sucessório',

    tipoContato: 'Tipo Contato',
    contato: 'Contato',
    contatos: 'Contatos',

    enderecos: 'Endereços',
    tipoEndereco: 'Tipo End.',
    cep: 'CEP',
    cepResidencial: 'CEP Residencial',
    endereco: 'Endereço',
    numero: 'Número',
    complemento: 'Complemento',
    bairro: 'Bairro',
    cidade: 'Cidade',
    uf: 'UF',

    grupoPerguntaContextoPessoalFamilia: 'Contexto pessoal e familiar',
    grupoPerguntaContextoProfissional: 'Contexto Profissional',
    grupoPerguntaSeguranca: 'Segurança',
    grupoPerguntaIndependenciaFinanceira: 'Independência Financeira',


    objetivos: 'Objetivos',
    objetivosPrincipaisPreocupacoesPFCMLP: 'Objetivos e Principais Preocupações com o Planejamento Financeiro de Curto, Médio e Longo Prazo',

    riscos: 'Riscos',
    historicoSaudeRiscos: 'Histórico de Saúde/Riscos',
    peso: 'Peso (kg) (ex:75)',
    altura: 'Altura (cm) (ex:180)',
    imc: 'IMC',
    tipoClassificacaoImc: 'Classificação IMC',
    fumante: 'Fumante',
    tipoFumo: 'Tipo de Fumo',
    tipoFumoOutros: 'Tipo de Fumo - Outros',
    qtdeFumoSemanal: 'Qtde. Semanal',
    utilizaMoto: 'Utiliza Moto',
    tipoUtilizacaoMoto: 'Tipo de Utilização da Moto',
    tipoEsporteHobbyRisco: 'Esporte/Hobbie de Risco',
    tipoEsporteHobbyRiscoOutros: 'Esporte/Hobbie de Risco - Outros',
    informarHistoricoSaudeFamiliaresEnteadao: 'Informar Histórico de Saúde Familiares',

    tipoCondicaoSaude: 'Condição de Saúde',
    informacoesComplementares: 'Informações Complementares',
    idadeDesdeDoenca: 'Desde (Idade)',
    tipoSituacaoDoenca: 'Situação Atual',
    idadeCirurgia: 'Quando (Idade)',
    procedimentoCirurgia: 'Procedimento',

    historicoSaudeFamiliares: 'Histórico de Saúde Familiares',
    tipoFamiliar: 'Familiar',
    vivo: 'Vivo?',
    idadeObito: 'Idade óbito',
    causaObito: 'Causa óbito',
 


    descricao: 'Descrição',
    observacao: 'Observação',



    integracaoInclusaoSiteInstitucional: 'Você sabe o que precisa fazer para alcançar a sua Independência Financeira?',



    grupoDespesa: 'Grupo Despesa',
    descricaoGrupoDespesaOutras: 'Grupo Despesa - Outras',


    despesaMensal: 'Despesa Mensal',
    tipoDespesaMensal: 'Tipo Despesa Mensal',
     
    diaDia: 'Dia a Dia',
    diaDiaDespesasTemporarias: 'Dia a Dia - Despesas Temporárias',
    diaDiaDespesasTemporariasDescricaoCurta: 'Despesas Temporárias',
    diaDiaDespesasVitalicias: 'Dia a Dia - Despesas Vitalícias',
    tipoDiaDia: 'Tipo Dia a Dia',
    //parentescoDependente: 'Dependente',
    mesesAteAposentarQuebraEspecial: 'Meses antes<br/>Aposentar', //'Meses até<br/>Aposentar',
    mesesAposAposentarQuebraEspecial: 'Meses após<br/>Aposentar', //'Meses após<br/>Aposentar',
    valorNaAposentadoria: 'Valor na ' + aposentadoria,
    valorNaAposentadoriaCurto: 'Vr. ' + aposentadoria,
    tipoPeriodicidadePagto: 'Periodicidade',
    tipoFormaPagto: 'Forma de Pagamento',
    anoFinal: 'Ano Final',
    valorAcumulado: 'Valor Acumulado',
    despesaPessoal: 'Despesa Pessoal',
    despesaPessoalCurto: 'Desp. Pessoal',
    consumirRecursosFinanceirosEmVezDaReceitaMensal: 'Consumir Recursos Financeiros em vez da Receita Mensal',
    pessoal: 'Pessoal',
    periodoDiaDia: 'Período do Dia a Dia',
    alterarPeriodoDiaDia: 'Alterar Período',
    diaDiaIraOcorrerAteExpectativaVida: 'Despesa irá ocorrer até a Expectativa de Vida',
    valorCompra: 'Valor Compra',
    titularRespValorCompra: 'Responsável',


    educacao: 'Educação',
    tipoEducacao: 'Tipo Educação',
    familiar: 'Familiar',
    idadeInicial: 'Idade Inicial',
    idadeFinal: 'Idade Final',
    tipoSegurado: 'Segurado',


    financiamento: 'Financiamento',
    tipoFinanciamento: 'Tipo Financiamento',
    tabelaSAC: 'Tabela SAC',
    valorParcela: 'Valor Parcela',
    valorParcelaAtual: 'Valor Parcela Atual',
    qtdeParcelas: 'Parcelas Pendentes',
    qtdeParcelasAbreviada: 'Parcelas Pend.',
    valorTotalParcelas: 'Valor Parcelas Pendentes',
    valorTotalParcelasAbreviado: 'Vr.Parcelas Pendentes',
    valorSaldoDevedor: 'Saldo Devedor',
    possuiParcelasAdicionais: 'Possui parcelas adicionais',
    temSeguro: 'Tem Seguro?',
    financiamentoFuturo: 'Financiamento irá ocorrer no futuro',
    financiamentoFuturoAindaNaoContratado: 'Financiamento ainda não foi contratado',
    financiamentoSeraQuitadoAntesUltimaParcela: 'Financiamento será quitado antes da última parcela',
    ultimaParcelaFinanciamento: 'Última Parcela Financiamento',
    alterarPercSeguroConjuge: 'Alterar %',


    numeroConsorcio: 'Número Consórcio',
    nomeConsorciado: 'Consorciado',
    administradoraConsorcio: 'Administradora Consórcio',
    tipoConsorcio: 'Tipo Consórcio',
    grupoConsorcio: 'Grupo',
    cotaConsorcio: 'Cota',
    pessoaFisicaJuridica: 'Pessoa Física/Jurídica',
    statusGrupoConsorcio: 'Status do Grupo',
    dataAssembleia: 'Data Assembleia',
    anoMesAssembleia: 'Ano/Mês Assembleira',
    numeroAssembleiaParticipacao: 'Nº Assembleia de Participação',
    valorCreditoConsorcio: 'Valor Crédito',
    valorParcelaInicial: 'Valor Parcela Inicial',
    dataVenctoBoleto: 'Data Vencimento Boleto',
    anoMesVenctoBoleto: 'Ano/Mês Vencimento Boleto',
    prazoTotalGrupoConsorcio: 'Prazo Total',
    numeroTotalParticipantesGrupoConsorcio: 'Total Participantes',
    taxaAdministracaoTotal: 'Taxa Adm. Total',
    percFundoReserva: '% Fundo Reserva',
    percSeguroVida: '% Seguro Vida',
    percLanceEmbutido: '% Lance Embutido',
    percLanceFixo: '% Lance Fixo',


    projetoVidaSonho: 'Projeto de Vida/Sonho',
    projetosVidaSonho: 'Projetos de Vida/Sonho',
    projetoVidaSonhoTracarMeta: 'Projeto de Vida/Sonhos - Metas',
    projetoVidaSonho1aParte: 'Projeto de',
    projetoVidaSonho2aParte: 'Vida/Sonho',
    tipoProjetoVidaSonho: 'Projeto de Vida/Sonho',
    idadeRealizacao: 'Idade Realização',
    anoRealizacao: 'Ano Realização',
    idadeAnoRealizacao: 'Idade/Ano Realização',
    tracarMeta: 'Traçar Meta',
    mesAnoInicioAcumulacao: 'Mês/Ano Início Acumulação',
    mesAnoInicioAcumulacaoQuebraEspecial: 'Mês/Ano Início<br/>Acumulação',
    mesAnoFimAcumulacao: 'Mês/Ano Fim Acumulação',
    mesAnoFimAcumulacaoQuebraEspecial: 'Mês/Ano Fim <br/>Acumulação',
    periodoAcumulacao: 'Período Acumulação',
    mesRealizacao: 'Mês Realização',
    mesAnoRealizacao: 'Mês/Ano Realização',
    tipoPeriodicidadeAporte: 'Periodicidade Aporte',
    valorAcumulacaoJaRealizado: 'Valor já Acumulado',
    valorAcumulacaoJaRealizadoQuebraEspecial: 'Valor já<br/>Acumulado',
    recorrencia: 'Recorrência',
    anoLimiteRecorrencia: 'Ano Limite Recorrência',
    controlarAportes: 'Controlar Aportes',
    diaAporte: 'Dia do Aporte',
    valorAcumulacaoJaRealizadoVF: 'Valor já Acumulado (VF)',
    valorAcumulacaoJaRealizadoVFQuebraEspecial: 'Valor já<br/>Acumulado (VF)',
    valorAcumulacaoJaRealizadoVFAbreviado: 'Valor Acumulado(VF)',
    valorAcumulacaoNecessarioVP: 'Vr.Necessário(VP)',
    valorAcumulacaoPendenteQuebraEspecial: 'Valor Pendente<br/>Acumulação',
    qtdeAportes: 'Qtde. Aportes',
    valorAporteProjetoVidaSonho: 'Valor Aporte',



    patrimonioImobilizado: 'Patrimônio Imobilizado',
    patrimonioImobilizado1aParte: 'Patrimônio',
    patrimonioImobilizado2aParte: 'Imobilizado',
    patrimonioImobilizadoBensMoveisImoveis: 'Bens Móveis e Imóveis',
    patrimonioImobilizadoNegocios: 'Negócios',
    tipoPatrimonioImobilizado: 'Tipo Patrimônio Imobilizado',
    alugado: 'Alugado',
    valorAluguel: 'Valor Aluguel',
    perc: '%',
    naoInventariar: 'Não Inventariar',
    considerarGarantiaMorteInvalidez: 'Considerar como Garantia na Morte/Invalidez',
    tipoImovel: 'Tipo de Imóvel',
    dataCompra: 'Data Compra',


    receitaMensal: 'Receita Mensal',
    receitaMensal1aParte: 'Receita',
    receitaMensal2aParte: 'Mensal',
    tipoReceitaMensal: 'Tipo Receita Mensal',
    tipoPeriodicidadeRecebto: 'Periodicidade',
    valor: 'Valor',
    valorTotal: 'Valor Total',
    valorLiquido: 'Valor Líquido',
    valorBruto: 'Valor Bruto',
    valorInss: 'INSS',
    valorPensao: 'Pensão',
    valorInssInvalidez: 'INSS Invalidez',
    valorPensaoInvalidez: 'Pensão Invalidez',
    valorInssMorte: 'INSS Morte',
    valorPensaoMorte: 'Pensão Morte',
    valorInssAposentadoria: 'INSS ' + aposentadoria,
    valorPensaoAposentadoria: 'Pensão ' + aposentadoria,
    alterarValorInssMorteAposentadoria: 'Alterar INSS Morte/' + aposentadoria,
    alterarValorPensaoMorteAposentadoria: 'Alterar Pensão Morte/' + aposentadoria,
    periodoReceitaMensal: 'Período da Receita',
    alterarTipoCalculoAcumulado: 'Alterar Período',
    mesAnoInicial: 'Mês/Ano Inicial',
    mesAnoInicialIdadeQuebraEspecial: 'Mês/Ano Inicial',
    mesAnoFinal: 'Mês/Ano Final',
    mesAnoFinalIdadeQuebraEspecial: 'Mês/Ano Final',
    receitaMensalAteAposentadoria: 'Receita Mensal até ' + aposentadoria,
    inssInvalidezMorteAposentadoria: 'INSS Invalidez/Morte/' + aposentadoria,
    temSeguroVidaEmGrupo: 'Tem Seguro Vida em Grupo',
    tipoMultiploSalarioSeguroVidaEmGrupo: 'Múltiplo Salário',
    valorCSSeguroVidaEmGrupoMorte: 'CS Morte',
    valorCSSeguroVidaEmGrupoMorteAcidental: 'CS Morte Acidental',
    valorCSSeguroVidaEmGrupoInvalidezPorAcidente: 'CS Invalidez por Acidente',
    valorCSSeguroVidaEmGrupoInvalidezFuncionalPorDoenca: 'CS Invalidez Funcional por Doença',
    valorCSSeguroVidaEmGrupoFuneral: 'Assistência Funeral',
    extensivelClienteConjuge: 'Extensível',
    extensivelFilhao: 'Extensível Filhas(os)',
    inicio: 'Início',


    recursosFinanceiros: 'Recursos Financeiros',
    recursosFinanceiros1aParte: 'Recursos',
    recursosFinanceiros2aParte: 'Financeiros',
    recursoFinanceiro: 'Recurso Financeiro',
    tipoRecursoFinanceiro: 'Tipo Recurso Financeiro',
    valorReservaAtual: 'Reserva Atual',
    valorAporte: 'Aporte',
    valorAporteEmpresa: 'Aporte Empresa',
    tipoPeriodicidadeAporteDividendos: 'Periodicidade',
    tipoPeriodicidadeAporteDividendosAbreviado: 'Period.',
    aporteAteAposentadoria: 'O aporte será feita até a ' + aposentadoria.toLowerCase(),
    aporteOriundoReceitaMensal: 'O aporte é oriundo da receita mensal',
    aporteOriundoRealocacaoCarteiraInvestimentos: 'O aporte é oriundo de uma realocação da Carteira de Investimentos',
    idRecursoFinanceiroOrigemRealocacaoCarteiraInvestimentos: 'Recurso Financeiro Origem',
    naoConsiderarGarantiaMorteInvalidez: 'Não considerar como Garantia na Morte/Invalidez',
    naoConsiderarGarantiaMorte: 'Não considerar como Garantia na Morte',
    naoConsiderarGarantiaInvalidez: 'Não considerar como Garantia na Invalidez',
    percRentabilidadeAnual: '% Rentabilidade Anual',
    percRentabilidadeAnualCurto: '% Rentab.',
    taxaAnual: 'Taxa Anual (%)',
    vencimento: 'Vencimento',
    instituicao: 'Instituição',
    nomeAcao: 'Nome da Ação',
    tipoRegimeTributacao: 'Regime Tributação',
    nomeFundo: 'Nome do Fundo',
    cnpjFundo: 'CNPJ do Fundo',
    valorReservadoPVSQuebraEspecial: 'Valor Reservado<br/>Projeto de<br/>Vida/Sonho',
    saldoNaoReservadoQuebraEspecial: 'Saldo Não<br/>Reservado',
    //emAndamento: 'Em andamento',



    planejamentoEspecializacaoPeriodoSabatico: 'Planejamento Especialização / Período Sabático',
    especializacaoPeriodoSabatico: 'Especialização / Período Sabático',
    antesEspecializacaoPeriodoSabatico: 'Antes da Especialização / Período Sabático',
    duranteEspecializacaoPeriodoSabatico: 'Durante a Especialização / Período Sabático',
    depoisEspecializacaoPeriodoSabatico: 'Depois da Especialização / Período Sabático',
    saldo: 'Saldo',
    antes: 'Antes',
    durante: 'Durante',
    depois: 'Depois',
    planejamento: 'Planejamento',
    fluxoCaixa: 'Fluxo de Caixa',
   


    instituicaoFinanceira: 'Instituição Financeira',

    cotacao: 'Cotação',
    resumoCotacao: 'Resumo da Cotação',
    tabelaEvolucaoValorResgate: 'Tabela de Evolução do Valor de Resgate',
    tabelaEvolucaoCS: 'Tabela de Evolução do Capital Segurado',
    tabelaEvolucaoCancelamentoApolice: 'Tabela de Evolução do Cancelamento da Apólice',
    comparacaoPreco: 'Comparação de Preço',
    valorPremioAnualizadoAcumulado: 'Prêmio Anualizado Acumulado',
    valorPremioAnualizadoInvestido: 'Prêmio Anualizado Investido',
    valorResgate: 'Valor Resgate',
    valorSaldado: 'Valor Saldado',
    beneficioProlongado: 'Benefício Prolongado',
    //valorEconomiaInventarioSeguroVida: 'Economia Inventário',
    //valorResgateMaisEconomiaInventarioSeguroVida: 'Valor Resgate + Economia Inventário',

    links: 'Links',
    proposta: 'Proposta',
    apolice: 'Apólice',
    seguroVida: 'Seguro de Vida',
    nomeLiderUnidade: 'LU',//'Líder',
    nomeConsultor: 'Consultor',//'Assessor',
    numeroApolice: 'Número da Apólice',
    seguradora: 'Seguradora',
    seguradoraOutras: 'Seguradora - Outras',
    formaPagamento: 'Forma Pagamento',
    dataEmissao: 'Data de Emissão',
    anoMesEmissao: 'Ano Mês de Emissão',
    diaMesEmissao: 'Dia/Mês de Emissão',
    dataAssinatura: 'Data Assinatura',
    anoMesAssinatura: 'Ano Mês Assinatura',
    dataUltimaAlteracao: 'Data Última Alteração',
    anoMesUltimaAlteracao: 'Ano Mês Última Alteração',
    statusProposta: 'Status Proposta',
    statusApolice: 'Status Apólice',
    numeroCotacao: 'Número da Cotação',
    dataCotacao: 'Data da Cotação',
    utilizarApenasComparacaoPreco: 'Utilizar apenas para comparação de preço',
    numeroProposta: 'Número da Proposta',
    producaoPropria: 'Produção Própria',
    rendaSegurado: 'Renda',
    mapaPropostas: 'Mapa de Propostas',
    pendentesMesAtual2Meses: 'Pendentes Mês Atual + 2 Meses',
    pagasMesAtual: 'Pagas Mês Atual',

    coberturas: 'Coberturas',
    cobertura: 'Cobertura',
    grupoCoberturaSeguroVida: 'Grupo Cobertura',
    tipoCoberturaSeguroVida: 'Tipo Cobertura',
    valorCS: 'Capital Segurado',
    valorRenda: 'Renda Mensal',
    valorDiaria: 'Valor da Diária',
    dataTerminoCobertura: 'Data Término Cobertura',
    tipoPrazoRecebtoRenda: 'Prazo Recebto. Renda',
    valorPremio: 'Prêmio',
    tipoPeriodicidadePagtoPremio: 'Periodicidade Prêmio',
    valorCSSobrevivencia: 'CS Sobrevivência',
    tempoCoberturaSeguroVida: 'Tempo Cobertura',
    statusCobertura: 'Status Cobertura',
    descricaoCobertura: 'Descrição da Cobertura',
    valorPremioLiquido: 'Prêmio Líquido',
    valorIof: 'Valor IOF',
    classeAjusteRisco: 'Classe de Ajuste/Risco',
    valorPremioExtra: 'Valor Prêmio Extra',
    duracaoPremioExtra: 'Duração Prêmio Extra',
    valorRendaMensal: 'Valor Renda Mensal',
    morteQualquerCausa: 'Morte Qualquer Causa',
    morteAcidental: 'Morte Acidental',
    valorPremioTotal: 'Prêmio Total',
    valorPremioAnualizado: 'Prêmio Anualizado',

    banco: 'Banco',
    agencia: 'Agência',
    conta: 'Conta',
    diaVencimento: 'Dia Vencimento',
    numeroParcela: 'Número Parcela',
    statusParcela: 'Status Parcela',
    tipoBaixaParcela: 'Tipo Baixa',
    dataVencimento: 'Data Vencimento',
    anoMesVencimento: 'Ano Mês de Vencimento',
    dataCompetencia: 'Data Competência',
    dataInicioVigencia: 'Data Início Vigência',
    dataFimVigencia: 'Data Fim Vigência',
    dataPagamento: 'Data Pagamento',
    anoMesPagamento: 'Ano Mês de Pagamento',
    dataCorte: 'Data Corte',
    valorAPagar: 'Valor à Pagar',
    valorPago: 'Valor Pago',

    dataGeracaoComissao: 'Data Geração Comissão',
    anoMesGeracaoComissao: 'Ano Mês Geração Comissão',
    valorBaseCalculoComissao: 'Base Cálculo Comissão',
    percComissao: '% Comissão',
    valorComissao: 'Comissão',
    percComissaoCoCorretagem: '% Comissão Co-Corretagem',
    valorComissaoCoCorretagem: 'Comissão Co-Corretagem',
    valorComissaoTotal: 'Comissão Total',
    numeroCobertura: 'Número Cobertura',
    codigoCobertura: 'Código Cobertura',



    percResp: '% Responsabilidade',
    //tipoIR: 'Tipo I.R.',
    idadeDataSimulacao: 'Idade Data Simulação',
    idadeDataSimulacaoQuebraEspecial: 'Idade Data<br/>Simulação',
    idadeVaiAposentar: 'Vai trabalhar até', //'Idade vai Aposentar',
    idadeVaiAposentarQuebraEspecial: 'Vai trabalhar<br/>até', //'Idade vai<br/>Aposentar',
    idadeInicioInss: 'Idade Início INSS',
    idadeExpectativaVida: 'Expectativa de Vida',
    idadeExpectativaVidaQuebraEspecial: 'Expectativa<br/>de Vida',
    idadeSimulacaoMorte: 'Cobrir ' + AuthService.obterTextoMorte() + ' Até',
    idadeSimulacaoMorteQuebraEspecial: 'Cobrir<br/>' + AuthService.obterTextoMorte() + ' Até',
    tipoParametros: 'Parâmetros',
    alterarIdadesAutomaticas: 'Alterar Idades Automáticas',
    alterarPercRespAutomatico: 'Alterar % Responsabilidade Automático',
    alterarValoresAutomaticos: 'Alterar Valores Automáticos',



    alertaCamposObrigatorios: 'Existem campos obrigatórios e/ou importantes que não foram preenchidos e que podem impactar o resultado do Estudo',
    espacoAlertaCamposObrigatorios: '&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp',


    tipoInventario: 'Tipo Inventário',
    sucessaoVitalicia: 'Sucessão Vitalícia',
    projecaoSucessaoVitalicia: 'Projeção da Sucessão Vitalícia',
    percDespesasAdvocaticias: '% Despesas Advocatícias',
    despesasAdvocaticias: 'Despesas Advocatícias',
    percCustasJudiciais: '% Custas Judiciais',
    custasJudiciais: 'Custas Judiciais',
    percDespesasAdvocaticiasCustasJudiciaisQuebraEspecial: '% Advogado<br/>+ Custas',
    itcmd: 'ITCMD',
    percItcmd: '% ITCMD',
    percItcmdQuebraEspecial: '%<br/>ITCMD',
    emergencial: 'Emergencial',
    mesesEmergencial: 'Meses Emergencial',
    inventario: 'Inventário',
    composicaoInventario: 'Composição do Inventário',
    sucessaoVitaliciaOpcoesAporteImediato: 'Sucessão Vitalícia: OPÇÕES DE APORTE IMEDIATO', // mudança da fonte: capslock
    sucessaoVitaliciaOpcoes: 'Sucessão Vitalícia: OPÇÕES', // mudança da fonte: capslock
    necessidade: 'Necessidade',
    saldoAtual: 'Saldo Atual',
    aporteComplementar: 'Aporte Complementar',
    premioAnual: 'Prêmio Anual',
    premioAnualPercCarteira: 'Prêmio Anual<br/>(% Carteira)', // mudança da fonte: <br/>
    valorCSAtual: 'Capital Segurado Atual',
    valorCSComplementar: 'Capital Segurado Compl.',


    dadosCadastrais: 'Dados Cadastrais',
    perfilCliente: 'Perfil do Cliente',
    tipoPoliticaInvestimento: 'Política de Investimento',
    perguntas: 'Perguntas',

    institucional: 'Institucional',

    expandir: 'Expandir',


    cenario: 'Cenário',
    cenarios: 'Cenários',
    cenarioPrincipal: 'Cenário Principal',
    parametros: 'Parâmetros',

    cenariosTaxas: 'Cenários e Taxas',
    taxas: 'Taxas',
    taxaCDIAnual: '% CDI Anual',
    taxaCDIAnualQuebraEspecial: '% CDI<br/>Anual',
    retornoCDI: '% Retorno sobre o CDI',
    retornoCDIQuebraEspecial: '% Retorno<br/>sobre o CDI',
    taxaJurosAnual: '% Taxa Juros Anual',
    taxaJuros: 'Taxa de Juros',
    taxaJurosQuebraEspecial: 'Taxa de<br/>Juros',
    tipoMomentoTributacaoIR: 'Momento Tributação I.R.',
    percIR: '% I.R.',
    tempoMinimoEntreAportesResgatesCalculoIRAnos: 'Tempo mínimo entre Aportes e Resgates (Anos)',
    taxaInflacaoAnual: '% Inflação Anual',
    taxaInflacaoAnualQuebraEspecial: '% Inflação<br/>Anual',
    taxaInflacao: 'Inflação',
    taxaRetornoAnualReal: '% Retorno Anual Real',
    taxaRetornoAnualRealQuebraEspecial: '% Retorno<br/>Anual Real',
    taxaRetornoRealAnual: '% Retorno Real Anual',
    taxaJurosAnualFgts: '% Taxa Juros Anual FGTS',
    taxaJurosAnualFgtsQuebraEspecial: '% Taxa Juros<br/>Anual FGTS',
    taxaRetornoAnualFgtsReal: '% Retorno Anual FGTS Real',
    taxaRetornoAnualFgtsRealQuebraEspecial: '% Retorno Anual<br/>FGTS Real',
    comSemDiaDiaEstudo: 'Com/Sem DiaDia',
    dataSimulacaoEstudo: 'Data da Simulação',
    dataSimulacaoEstudoQuebraEspecial: 'Data da<br/>Simulação',
    utilizarPercRentabilidadeAnualRecursosFinanceiros: 'Utilizar "% Rentabilidade Anual" de cada Recurso Financeiro, quando informado',
    diferenciarTaxasAposAposentadoria: 'Diferenciar Taxas após ' + aposentadoria,
    idades: 'Idades',
    periodo: 'Período',
    ateAposentadoria: 'Até ' + aposentadoria,
    aposAposentadoria: 'Após ' + aposentadoria,
    invalidez: 'Invalidez',

    revisaoDados: 'Revisão dos Dados',

    exclusoes: 'Exclusões',
    exclusaoFamiliares: 'Desconsiderar Familiares',
    familiarASerExcluido: 'Familiar a ser Desconsiderado',

    exclusaoDespesasGarantias: 'Desconsiderar Despesas e Garantias',
    despesaGarantiaASerExcluida: 'Despesa/Garantia a ser Desconsiderada',

    configuracaoGestaoPatrimonial: 'Gestão Patrimonial',
    exibirTabelaEvolucaoValorResgateCarteiraInvestimentos: 'Exibir a Tabela de Evolução Valor Resgate na Carteira de Investimentos',
    exibirCapitalSeguradoCarteiraInvestimentos: 'Exibir o Capital Segurado na Carteira de Investimentos',
    //exibirEconomiaInventarioSeguroVidaCarteiraInvestimentos:
    //    'Exibir a Economia de Inventário com o Seguro de Vida na Carteira de Investimentos',
    premioVidaInteiraOriundoCarteiraInvestimentos: 'Prêmio do Vida Inteira é oriundo da Carteira de Investimentos',
    idRecursoFinanceiroOrigemOrigemPremioVidaInteira: 'Recurso Financeiro Origem para Pagamento Prêmio Vida Inteira',
    idadeSimularResgateVidaInteira: 'Idade para simular o Resgate do Vida Inteira $1$',
    aplicarTaxaInflacaoSimulacaoResgateVidaInteira: 'Aplicar Inflação na Simulação do Resgate do Vida Inteira',
    aplicarTaxaInflacaoCarteiraInvestimentos: 'Aplicar Inflação na Carteira de Investimentos',
    exibirResumoSeguroVidaCotacao: 'Exibir o Resumo da Cotação do Seguro de Vida',
    exibirPercPremioAnualizadoAcumuladoEmRelacaoCapitalSegurado: 'Exibir o % Prêmio Anualizado Acumulado em relação ao Capital Segurado',
    exibirValorResgateSomenteAPartirQuitacaoSeguroVidaInteira: 'Exibir o Valor de Resgate somente a partir da quitação do Seguro Vida Inteira',
    //    'Exibir a Economia de Inventário com o Seguro de Vida na Tabela de Evolução Valor Resgate',
    aplicarTaxaInflacaoTabelaEvolucaoValorResgate: 'Aplicar Inflação na Tabela de Evolução Valor Resgate',
    calcularPremioAnualizadoAcumuladoSomaSimples:
        'Calcular o Prêmio Anualizado Acumulado pela soma simples do Prêmio Anualizado corrigido pela Inflação, para que o cálculo fique com o mesmo critério da planilha da XP',
    exibirTambemTabelaEvolucaoValorResgateSemTaxaInflacao: 'Exibir também a Tabela de Evolução Valor Resgate sem a correção pela Inflação',
    exibirTabelaEvolucaoCS: 'Exibir a Tabela de Evolução do Capital Segurado',
    limitarQtdeAnosExibidosGraficosTabelasEvolucao: 'Limitar a quantidade de anos exibidos nos gráficos',
    exibirTabelaEvolucaoCancelamentoApolice: 'Exibir a Tabela de Evolução do Cancelamento da Apólice',
    exibirGraficoAnaliseInvestimentoVersusSeguroVidaInteira: 'Exibir o gráfico "Análise Investimento x Seguro Vida Inteira"',
    graficoAnaliseInvestimentoVersusSeguroVidaInteiraTipoMomentoTributacaoIR: 'Momento Tributação I.R.',
    graficoAnaliseInvestimentoVersusSeguroVidaInteiraPercIR: '% I.R.',
    graficoAnaliseInvestimentoVersusSeguroVidaInteiraConsiderarDespesasAdvocaticiasCustasJudiciais: 'Considerar o custo com Despesas Advocatícias e Custas Judiciais',
    graficoAnaliseInvestimentoVersusSeguroVidaInteiraConsiderarItcmd: 'Considerar o custo com o ITCMD',
    graficoAnaliseInvestimentoVersusSeguroVidaInteiraPercDesagio: '% Desagio',
    incluirSugestaoPrevidenciaPrivadaSeguroVidaInteiraSucessaoVitalicia: 'Incluir sugestão de Previdência Privada ou Seguro Vida Inteira para Sucessão Vitalícia',
    utilizarPrevidenciaPrivadaDeduzirNecessidadeSucessaoVitalicia: 'Utilizar a Previdência Privada para deduzir a necessidade de Sucessão Vitalícia',

    configuracaoAcumulacao: 'Acumulação',
    calcularAporteAtingirMetaRecursosFinanceirosInicioAposentadoria:
        'Calcular aporte necessário para atingir meta de Recursos Financeiros no início da ' + aposentadoria.toLowerCase(),
    valorMeta: 'Valor da Meta',
    metaInicioAposentadoria: 'Meta $1$',
    aplicarEconomiaReceitaMensalAteAposentadoriaReservaAposentadoria:
        'Aplicar a Economia da Receita Mensal, que ocorrerem até a ' + aposentadoria.toLowerCase() + ', na Reserva da ' + aposentadoria,
    economiaReceitaMensal: 'Economia Receita Mensal',
    deduzirComplementoReceitaMensalAteAposentadoriaReservaAposentadoria:
        'Deduzir o Complemento da Receita Mensal, que ocorrerem até a ' + aposentadoria.toLowerCase() + ', da Reserva da ' + aposentadoria,
    naoExibirOpcaoAporteUnico: 'Não exibir a opção de Aporte Único',
    utilizarVersaoSimplificadaGraficoDiagnosticoAposentadoriaIndependênciaFinanceira: 'Utilizar versão simplificada do Gráfico Diagnóstico Aposentadoria & Independência Financeira: somente com opções de aporte',
    naoCalcularAportesNecessariosRealizacaoPVS: 'Não calcular os aportes necessários para a realização dos Projetos de Vida/Sonho',
    naoConsiderarValorAcumulacaoJaRealizadoPVS: 'Não considerar os valores já acumulados para Projetos de Vida/Sonho',
    naoExibirHipotesesRetornoRealAposentadoria: 'Não exibir o gráfico de hipóteses de retorno real para ' + aposentadoria,
    calcularHipotesesRetornoRealAposentadoriaViverRentabilidade: 'Calcular gráfico de hipóteses de retorno real para ' + aposentadoria + ' para viver de rentabilidade',
    exibirHipotesesRetornoRealProjetoVidaSonho: 'Exibir os gráficos de hipóteses de retorno real para Projeto de Vida/Sonho',
    naoExibirAcumulacaoAposentadoriaProjetoVidaSonho:
        'Não exibir a sugestão de acumulação para Aposentadorie e Projeto de Vida/Sonho no Resumo',



    previdenciaPrivada: 'Previdência Privada',
    analiseBeneficiosPrevidenciaPrivada: 'Análise dos Benefícios da Previdência Privada',
    contribuiINSSRPPSOuAposentador: 'Contribui para o INSS/RPPS ou já é aposentado?',
    sugestao: 'Sugestão:',
    beneficio: 'Benefício:',
    beneficioSemPrevidenciaPrivada: 'Sem Aporte Previdênvia Privada',
    beneficioComPrevidenciaPrivada: 'Com Aporte $1$',
    ir: 'Imposto de Renda',
    rentabilidade: 'Rentabilidade',
    consumirReserva: 'Consumir Reserva (retirada = $1$)',
    preservarReserva: 'Preservar Reserva (retirada = $1$)',
    fundamento: 'Fundamento:',
    simulacao: 'Simulação',
    vantagens: 'Vantagens:',
    rendaTributavelAnual: 'Renda Tributável Anual',
    despesaDetutivelAnualSemPrevidenciaPrivada: 'Despesa Dedutível Anual sem Previdência Privada',

    aportePortabilidadeInicial: 'Aporte/ Portabilidade Inicial',
    aporteRegularMensal: 'Aporte Regular Mensal',
    rentabilidade12Meses: 'Rentabilidade<br/>12 Meses',
    rentabilidade24Meses: 'Rentabilidade<br/>24 Meses',
    taxaAdministracao: 'Taxa Administração',

    

    configuracaoProtecao: 'Proteção',
    naoAplicarTaxaJurosSeguroVida: 'Não aplicar as Taxas de Juros no cálculo do Seguro de Vida',
    naoExibirProtecaoRiscos: 'Não exibir a sugestão de Proteçao de Riscos',
    exibirMorteAte: 'Exibir Morte Até',
    naoExibirMorteVitalicia: 'Não exibir Morte Vitalícia',
    naoExibirMorteSucessaoVitalicia: 'Não exibir Morte Sucessão Vitalícia',
    naoExibirDITDITA: 'Não exibir DIT e/ou DITA',
    naoExibirDoencasGraves: 'Não exibir Doenças Graves',
    despesasAdicionaisDoencasGraves: 'Despesas Adicionais (médicas, etc) Doenças Graves',
    naoExibirInvalidez: 'Não exibir Invalidez',
    naoExibirFuneral: 'Não exibir Funeral',
    naoExibirDespesasAcumuladasSemDiaDia: 'Não exibir a opção "Sem o Dia a Dia" do gráfico "Despesas Acumuladas ao longo do tempo".',

    paginaAdicional: 'Página Adicional',

    configuracaoRelatorio: 'Relatório',
    configuracaoRelatorioTitulo: 'Configuração do Relatório',
    detalhado: 'Detalhado',
    resumido: 'Resumido',
    disposicaoRelatorio: 'Disposição do Relatório', 
    imprimir: 'Imprimir',
    naoImprimir: 'Não Imprimir',

    exibir: 'Exibir',
    naoExibir: 'Não Exibir',

    agrupar: 'Agrupar',
    naoAgrupar: 'Não Agrupar',
    saltarPaginaQuebra: 'Saltar página a cada quebra de $campo$',
    gerarPDFQuebra: 'Gerar PDF separado a cada quebra de $campo$',
    enviarPDFEmail: 'Enviar PDF por E-mail para cada $campo$',
    assuntoEmail: 'Assunto do E-mail',
    textoEmail: 'Texto do E-mail',

    relatoriosGraficos: 'Gráficos e Relatórios',

    simulacaoFinanceira: 'Simulação Financeira',
    taxaJurosInflacao: 'Taxa de Juros e Inflação',
    aposentadoriaExpectativaVida: aposentadoria + ' e Expectativa de Vida',
    evolucaoReservaFinanceira: 'Evolução da Reserva Financeira',
    planejamentoAposentadoria: 'Planejamento da ' + aposentadoria,
    percPoupanca: '% Poupança',
    retiradaReservaManterPadraoVidaDesejadoAposentadoria: '<b>Retirada da Reserva para manter<br/>Padrão de vida desejado </b>na ' + aposentadoria.toLowerCase(),
    aportesMensaisParaAtingirPadraoVidaDesejado: '<b>Aportes mensais até ' + aposentadoria.toLowerCase() +'</b><br/>para atingir o padrão de vida desejado',
    estrategiasPossiveis: 'Estratégias Possíveis',
    preservarReservaViverRentabilidade: '<b>Preservar a Reserva</b><br/>e viver de rentabilidade',
    consumirReservaAte: '<b>Consumir a Reserva</b><br/>até $1$ anos',
    aporteMensalAdicional: '<b>Aporte mensal</b> adicional',
    aporteUnicoAdicional: '<b>Aporte único</b> adicional',
    reservaInicioAposentadoriaComAportes: '<b>Reserva</b> aos <b>$1$</b> com aportes adicionais',
    retiradaMaximaSemNovosAportesAdicionais: '<b>Retirada máxima</b> sem novos aportes adicionais',
    cliqueParaAdicionarAutomaticamenteAporteMensal: 'Clique para adicionar automaticamente o aporte sugerido ao Aporte Mensal',
    cliqueParaAtribuirAutomaticamenteRetiradaMensal: 'Clique para atribuir automaticamente o Retirada Mensal',
    cliqueParaAtribuirAutomaticamenteAporteUnico: 'Clique para atribuir automaticamente o Aporte Único',
    
    aportesRetiradasPlanejados: 'Aportes e Retiradas Planejados',
    aportesRetiradasMensais: 'Aportes e Retiradas Mensais',
    aporteMensal: 'Aporte Mensal',
    aporteTotal: 'Aporte Total',
    aporteMedio: 'Aporte Médio',
    percReajusteAporteMensal: '% Reajuste Aporte Mensal',
    aumentoAporteMensal: 'Aumento Aporte Mensal',
    anosReajusteAporteMensal: 'Reajustar Aporte Mensal a cada X Anos',
    anosAumentoAporteMensal: 'Aumentar Aporte Mensal a cada X Anos',
    aporteMensalEvolucaoInflacaoQuebraEspecial: 'Aporte Mensal<br/>Evolução Inflação',
    retiradaMensal: 'Retirada Mensal',
    retiradaTotal: 'Retirada Total',
    retiradaMedia: 'Retirada Média',
    percReajusteRetiradaMensal: '% Reajuste Retirada Mensal',
    aumentoRetiradaMensal: 'Aumento Retirada Mensal',
    anosReajusteRetiradaMensal: 'Reajustar Retirada Mensal a cada X Anos',
    anosAumentoRetiradaMensal: 'Aumentar Retirada Mensal a cada X Anos',
    retiradaMensalEvolucaoInflacaoQuebraEspecial: 'Retirada Mensal<br/>Evolução Inflação',
    retornoFinanceiro: 'Retorno',
    aportesRetiradasAnuaisUnicos: 'Aportes e Retiradas Anuais ou Únicos',
    idadeFinalOpcional: 'Idade Final (opcional)',
    mesOpcional: 'Mês (opc.)',
    aporteAnual: 'Aporte Anual',
    retiradaAnual: 'Retirada Anual',
    anosExibicaoRelatorioEvolucaoReservaFinanceira: 'Exibir a Evolução da Reserva Financeira nos relatórios a cada',
    anoAnos: 'Ano(s)',
    comeco: 'Começo',
    aporteRetiradaAnual: 'Aporte/Retirada Anual',
    rendimentoMensal: 'Rendimento Mensal',
    rendimentoTotal: 'Rendimento Total',
    rendimentoMedio: 'Rendimento Médio',
    final: 'Final',
    exibirEvolucaoAporteMensalInflacao: 'Exibir Evolução Aporte Mensal pela Inflação',
    exibirEvolucaoRetiradaMensalInflacao: 'Exibir Evolução Retirada Mensal pela Inflação',
    exibirCapacidadePoupancaRelatorio: 'Exibir Capacidade de Poupança no Relatório',
    exibirAportesRetiradasPlanejadosRelatorio: 'Exibir Aportes e Retiradas Planejados no Relatório',
    naoExibirGraficoDiagnosticoAposentadoriaIndependenciaFinanceira:
        'Não exibir o gráfico Diagnóstico Aposentadoria & Independência Financeira no Relatório',
    exibirProtecaoSugeridaRelatorio: 'Exibir Proteção Sugerida no Relatório',
    exibirSucessaoVitaliciaRelatorio: 'Exibir Sucessão Vitalícia no Relatório',
    exibirComparacaoCenariosRelatorio: 'Exibir Comparação de Cenários no Relatório',
    exibirComparacaoEstudosRelatorio: 'Exibir Comparação de Estudos no Relatório',
    exibirConsideracoesFinaisRelatorio: 'Exibir $1$ no Relatório',


    configuracaoSimulacaoFinanceira: 'Configuração da Simulação Financeira',



    mapaFinanceiro: 'Mapa Financeiro',
    graficoMapaFinanceiro: 'Gráfico do Mapa Financeiro',
    incluirMapaFinanceiroRelatorioCliente: 'Incluir o Mapa Financeiro no Relatório do Cliente',


    valorMensal: 'Valor Mensal',
    valorMensalAtual: 'Valor Mensal Atual',
    valorMensalAtualQuebraEspecial: 'Valor Mensal<br/>Atual',
    valorNaAposentadoriaMensalQuebraEspecial: 'Valor Mensal<br/>' + aposentadoria,
    valorEstimado: 'Valor Estimado',
    valorAporteDividendosContribuicao: 'Aporte/ Dividendos/ Contribuição',
    total: 'TOTAL', // mudança da fonte: uppercase
    responsabilidades: 'Responsabilidades',
    garantias: 'Garantias',
    anosProteger: 'Anos a Proteger',
    anosProtegidos: 'Anos Protegidos',



    anos: 'anos',
    meses: 'meses',
    mes: 'mês',
    ano: 'ano',
    dia: 'dia',
    dias: 'dias',
    vitalicio: 'Vitalício',



    atual: 'Atual',
    ideal: 'Ideal',



    abertura: 'Abertura',
    oQueEsperarDesseTrabalho: 'O que esperar desse trabalho?',
    conferenciaDados: 'Conferência de Dados',
    conferenciaDadosClienteConjugeFamiliares: 'Conferência de Dados - Cliente, Cônjuge e Familiares',
    conferenciaDadosClienteConjugeFamiliaresObjetivos: 'Conferência de Dados - Cliente, Cônjuge e Familiares + Objetivos',
    conferenciaDadosClienteConjugeFamiliaresTaxas: 'Conferência de Dados - Cliente, Cônjuge e Familiares + Taxas',
    conclusao: 'Conclusão',

    despesasTotais: 'Despesas Totais',
    garantiasAtuais: 'Garantias Atuais',
    garantiasTotais: 'Garantias Totais',

    necessidadeProtecao: 'Necessidade de Proteção',



    selecionarCenariosTipoMorteInvalidez: 'Selecionar Cenários e Tipo de Morte/Invalidez',
    selecionarCenarios: 'Selecionar Cenários',
    selecionarTipoMorteInvalidez: 'Selecionar Tipo de Morte/Invalidez',

    
    acumulacao: 'Acumulação',
    aposentadoria: aposentadoria,
    aposentadoriaProjetoVidaSonho: aposentadoria + ' / Projeto de Vida/Sonho',


    protecao: 'Proteção',


    protecaoSugerida: 'Proteção Sugerida',
    perpetuarPatrimonio: 'Perpetuar Patrimônio',
    proporcionarConfortoFamilia: 'Proporcionar Conforto para a Família',
    protecaoEmVida: 'Proteção em Vida',
    DIT: 'DIT',
    DITA: 'DITA',
    doencasGraves: 'Doenças Graves',
    funeral: 'Funeral',

    diagnosticoAposentadoria: 'Diagnóstico ' + aposentadoria,

    hipotesesRetornoReal: 'Aporte mensal por hipóteses de retorno real',

    capacidadePoupanca: 'Capacidade de Poupança',
    receitaMensalMediaAtual: 'Receita Mensal Atual',
    despesaMensalMediaAtual: 'Despesa Mensal Atual',
    capacidadePoupancaMensalMediaAtual: 'Capacidade de Poupança Atual',
    retornoReal: 'Retorno Real',
    retornoRealAteAposAposentadoria: '(Até ' + aposentadoria + ' / Após)',
    valorPresenteReservaInicioAposentadoria: 'Reserva Início ' + aposentadoria,
    valorPresenteNecessidadesInicioAposentadoria: 'Necessidades Início ' + aposentadoria,
    valorPresenteDeficitReservaInicioAposentadoria: 'Déficit Reserva Início ' + aposentadoria,
    valorPresenteSuperavitReservaInicioAposentadoria: 'Superávit Reserva Início ' + aposentadoria,
    idadeLimiteReservaAposentadoria: 'Idade Limite Reserva Aposentadoria',
    ateMais100: '+ de 100',
    grauIndependenciaFinanceira: 'Independência Financeira até $1$ anos',

    necessidadeAposentadoria: 'Necessidades na ' + aposentadoria,
    necessidadeAposentadoriaDespesasNaoCobertas: '(Despesas não Cobertas pela Receita Prevista)',
    idadeDe: 'De',
    idadeAte: 'Até',
    receitaAposentadoriaMensal: 'Receita',
    despesaAposentadoriaMensal: 'Despesa',
    despesasExcedentesMensal: 'Despesas Excedentes',
    despesasExcedentesMensalAposentadoria: 'Despesas Excedentes ' + aposentadoria,

    aportePlanejadoAposentadoria: 'Aportes Planejados para a ' + aposentadoria,


    grafico: 'Gráfico',
    seriesAreaBar: 'Série para evitar viar Área quando séries são barra e só tem 1 série',

    media: 'Média',

    receitaAnual: 'Receita',
    receitaAnualLongoTempo: 'Previsão de Receitas',//'Receita Anual ao longo do tempo',
    tipoReceita: 'Receita',
    despesaAnual: 'Despesa',
    despesaAnualLongoTempo: 'Previsão de Despesas',//'Despesa Anual ao longo do tempo',
    tipoDespesa: 'Despesa',
    consumoReservaAposentadoria: 'Consumo da Reserva da ' + aposentadoria,
    retiradaReservaAposentadoriaComplementoReceitaMensal: 'Retirada Reserva ' + aposentadoria + ' p/ complementar Receita Mensal',
    aporteProjetoVidaSonhoAnual: 'Aporte Projeto de Vida/Sonho',
    aporteAposentadoriaAnual: 'Aporte ' + aposentadoria,
    aporteAposentadoriaEconomiaReceitaMensalAnual : 'Aporte ' + aposentadoria + ' c/ economia da Receita Mensal',
    retiradaReservaAposentadoriaComplementoReceitaMensalAnual : 'Retirada Reserva ' + aposentadoria + ' p/ complementar Receita Mensal',
    aporteAtingirMetaInicioAposentadoriaAnual : 'Aporte Atingir Meta Início ' + aposentadoria,
    balancoAnual: 'Balanço',
    balancoReceitaDespesaAnual: 'Capacidade de Poupança e Necessidades na Aposentadoria',//'Balanço Receita x Despesa Anual',
    liquidezFinanceiraAcumulada: 'Liquidez Financeira Acumulada',
    liquidezFinanceiraAcumuladaSuperavit: 'Liquidez Financeira Acumulada Superávit',
    liquidezFinanceiraAcumuladaDeficit: 'Liquidez Financeira Acumulada Déficit',
    despesasAcumuladasLongoTempo: 'Despesas Acumuladas ao longo do tempo',
    diagnosticoAposentadoriaDepositosParaAposentadoria: 'Depósitos para a ' + aposentadoria,
    tipoDiagnosticoAposentadoriaChart: 'Situação Atual / Situação Ideal',
    versus: ' x ',
    
    analisePatrimonial: 'Análise Patrimonial',
    ativos: 'ATIVOS', // mudança da fonte 
    passivos: 'Passivos',
    ativosMenosPassivos: 'Saldo Patrimonial',

    diagnosticoAposentadoriaIndependenciaFinanceira: 'Diagnóstico ' + aposentadoria + ' & Independência Financeira',
    situacaoAtualSuperavit: 'Situação Atual Superávit',
    situacaoAtualManterPadraoVidaSemNovosAportes: 'Manter Padrão de Vida sem Novos Aportes',
    situacaoAtualDeficit: 'Situação Atual Déficit',
    situacaoRealSuperavit: 'Situação Real Superávit',
    situacaoRealDeficit: 'Situação Real Déficit',
    aporteMensalPreservarReserva: '+ $1$ = Aporte mensal p/manter padrão de vida e preservar reserva',
    aporteMensalConsumirReserva: '+ $1$ = Aporte mensal p/manter padrão de vida e consumir reserva',
    retiradaMensalMaximaPreservarReserva: '$1$ = Retirada mensal máxima p/preservar reserva sem novos aportes',
    retiradaMensalMaximaConsumirReserva: '$1$ = Retirada mensal máxima p/consumir reserva sem novos aportes',


    resumo: 'Resumo',
    comparacaoFinanciamentos: 'Comparação Financiamentos',
    financiamentoSugerido: 'Financiamento Sugerido',
    financiamentoAlternativo: 'Financiamento Alternativo',
    analiseFinanciamentoSugerido: 'Análise do Financiamento Sugerido',
    graficoEcomoniaFinanciamentoSugerido: 'Economia com o Financiamento Sugerido',
    graficoPerdaFinanciamentoSugerido: 'Perda com o Financiamento Sugerido',
    comparacaoCenarios: 'Comparação Cenários',
    estudos: 'Estudos',
    comparacaoEstudos: 'Comparação Estudos',
    versao: 'Versão',
    evolucaoRecursosFinanceiros: 'Evolução dos Recursos Financeiros',


    parametrosGestaoPatrimonial: 'Parâmetros: Gestão Patrimonial',
    gestaoPatrimonial: 'Gestão Patrimonial',
    gestaoPatrimonialVisao: 'Visão',
    gestaoPatrimonialEstruturaFamiliar: 'Estrutura Familiar',
    gestaoPatrimonialFluxoCaixa: 'Fluxo de Caixa',
    gestaoPatrimonialResumoPatrimonial: 'Resumo Patrimonial',
    gestaoPatrimonialSucessao: 'Sucessão',
    gestaoPatrimonialEstrategiasFinanceiras: 'Estratégias Financeiras',
    gestaoPatrimonialProjecaoFutura: 'Projeção Futura',
    gestaoPatrimonialEstrategias: 'Estratégias',
    gestaoPatrimonialPlanejamento: 'Planejamento',

    carteiraInvestimentos: 'Carteira de Investimentos',
    analiseInvestimentoVersusSeguroVidaInteira: 'Análise Investimento x Seguro Vida Inteira',


    disclaimer: 'Disclaimer',



    dataResolucao: 'Data da Resolução',


    tabFintracksFormList1Configuracao: '1. Configuração',
    tabFintracksFormList2Usuario: '2. Usuário',
    tabFintracksFormList3Cliente: '3. Cliente',
    tabFintracksFormList4Cobranca: '4. Cobrança',
    tabFintracksFormList5Aporte: '5. Aportes',

    cobranca: 'Cobrança',



    buttonEntrar: 'Entrar',
    buttonSair: 'Sair',
    buttonCadastreSe: 'Ainda não é um guia financeiro? Cadastre-se',
    buttonEsqueceuSenha: 'Esqueceu a senha?',
    buttonCadastrar: 'Cadastrar',
    buttonAssinar: 'Assinar',
    buttonCancelar: 'Cancelar',
    buttonSalvarSenha: 'Salvar Senha',



    buttonRealizarAssinatura: 'Realizar Assinatura',
    buttonAlterarCartaoCredito: 'Alterar Cartão de Crédito',
    

    buttonAlterarCor: 'Alterar Cor',
    

    buttonNovoCliente: 'Novo Cliente',
    buttonNovoUsuario: 'Novo Usuário',
    buttonNovoParceiro: 'Novo Parceiro',
    buttonImportarXLSX: 'Importar XLSX',
    ButtonExcluirMarcados: 'Excluir Marcados',
    buttonExportarXLSX: 'Exportar XLSX',
    buttonExportarUmXLSXParaCada: 'Exportar um XLSX para cada',
    buttonIntegracao: 'Integração',
    buttonExcluirMarcados: 'Excluir Marcados',
    buttonUploadArquivos: 'Upload Arquivos',
    buttonProcessarArquivosPendentes: 'Processar Arquivos Pendentes',
    buttonProcessarArquivosMarcados: 'Processar Arquivos Marcados',
    buttonAtualizarStatusArquivos: 'Atualizar Status Arquivos',
    buttonVisualizarErrosProcessamento: 'Visualizar Erros',
    buttonUploadTabelaEvolucaoValorResgate: 'Upload Tabela Evolução Valor Resgate',
    buttonTransferir: 'Transferir',
    buttonBaixarModeloXLSXImportacaoClientes: 'Baixar Modelo XLSX para Importação de Clientes',
    modeloXLSXImportacaoClientes: 'Modelo de Importação de Clientes',
    buttonSubmitSalvar: 'Salvar',
    buttonCompartilhar: 'Compartilhar',
    buttonCopiar: 'Copiar',


    buttonGerarLinkCliente: 'Gerar link para o Cliente',
    buttonAlterarConfiguracaoRelatorio: 'Alterar Configuração Relatório',


    
    processando: 'Processando ...',

    buttonGerarTokenIntegracao: 'Gerar Token Integração',

    buttonIncluirWhiteLabel: 'Adicionar um White Label',
    buttonIncluirFilialUnidade: 'Adicionar uma Filial/Unidade',
    buttonIncluirFunil: 'Adicionar um Funil',
    buttonIncluirFunilEtapa: 'Adicionar uma Etapa',
    buttonIncluirUsuario: 'Adicionar um Usuário',
    buttonIncluirSeguradora: 'Adicionar uma Seguradora',

    buttonImportarLogo: 'Logo',
    buttonImportarLogoLoginSidenav: 'Logo Login / Menu (Opcional)',
    buttonImportarBackground: 'Imagem de Fundo (Opcional)',
    buttonImportarBackgroundCapaRelatorio: 'Imagem Capa Relat.Retrato (Opcional)',
    buttonImportarBackgroundCapaRelatorioPaisagem: 'Imagem Capa Relat.Paisagem (Opcional)',
    buttonImportarBackgroundPaginaRelatorio: 'Imagem Página Relat.Retrato (Opcional)',
    buttonImportarBackgroundPaginaRelatorioPaisagem: 'Imagem Página Relat.Paisagem (Opcional)',

    buttonIncluirPerfilUsuario: 'Adicionar um Perfil/Usuário',

    buttonIncluirCor: 'Adicionar uma Cor',

    buttonIncluirTipoPendencia: 'Adicionar um Tipo de Pendência',

    buttonCarregarPDF: 'Carregar PDF',

    buttonIncluirFormulario: 'Adicionar um Formulário',
    buttonIncluirTemplate: 'Adicionar um Template',
    buttonIncluirPagina: 'Adicionar uma Pagina',
    buttonIncluirPaginaAPartirDeUmaExistente: 'Adicionar uma Pagina a partir de uma Existente',
    buttonIncluirItemPagina: 'Adicionar Item na Pagina',
    buttonIncluirItemGestaoPatrimonial: 'Adicionar Item',



    buttonIncluirTipoInvestimento: 'Adicionar um Tipo de Investimento',


    buttonIncluirTipoPilar: 'Adicionar um Pilar',


    buttonIncluirFiltro: 'Adicionar um Filtro',


    buttonAtualizarDashboard: 'Atualizar o Dashboard',


    buttonIncluirVersao: 'Incluir Versão',


    buttonIncluirFamiliar: 'Adicionar um Familiar',
    buttonIncluirEndereco: 'Adicionar um Endereço',
    buttonBuscarEndereco: 'Buscar Endereço',
    buttonIncluirContato: 'Adicionar um Telefone, E-mail, ...',
    buttonIncluirContaInvestimentos: 'Adicionar uma Conta de Investimentos',


    buttonIncluirEsporteHobbyRisco: 'Adicionar um Esporte/Hobby de Risco',
    buttonIncluirCondicaoSaude: 'Adicionar uma Condição de Saúde',
    buttonIncluirHistoricoSaudeFamiliares: 'Adicionar um Histórico de Saúde Familiares',


    buttonIncluirReceitaMensal: 'Adicionar uma Receita Mensal',
    buttonIncluirDiaDia: 'Adicionar uma Despesa do Dia a Dia',
    buttonIncluirEducacao: 'Adicionar uma Etapa de Educação',
    buttonIncluirFinanciamento: 'Adicionar um Financiamento',
    buttonIncluirParcelasAdicionais:'Adicionar uma Parcela',
    buttonIncluirConsorcio: 'Adicionar um Consórcio',
    buttonIncluirProjetoVidaSonho: 'Adicionar um Projeto de Vida/Sonho',


    buttonIncluirPatrimonioImobilizado: 'Adicionar um Patrimônio Imobilizado',
    buttonIncluirRecursosFinanceiros: 'Adicionar um Recurso Financeiro',
    buttonIncluirSeguroVidaCotacao: 'Adicionar uma Cotação',
    buttonIncluirSeguroVidaProposta: 'Adicionar uma Proposta',
    buttonIncluirSeguroVidaApolice: 'Adicionar uma Apólice',
    buttonIncluirSeguroVidaCobertura: 'Adicionar uma Cobertura',



    buttonIncluirSimulacao: 'Adicionar uma Simulação',
    buttonIncluirAporte: 'Adicionar um Aporte',
    


    buttonIncluirConsideracaoFinal: 'Adicionar mais uma Página',



    buttonGerarLancamentosPlanejamentoEspecializacaoPeriodoSabatico: 'Gerar Lançamentos do Planejamento da Especialização/Período Sabático',



    buttonConcluir: 'Concluir',
    buttonConcluirEtapa: 'Concluir Etapa',
    buttonVoltar: 'Voltar',
    buttonProximo: 'Próximo',

    buttonCopiaEducacaoFilhaoBaseOutraosFilhaos: 'Copiar valores da Educação de $1 para $2',

    buttonIncluirCenario: 'Adicionar um Cenário',
    buttonAlterarCenario: 'Alterar o Cenário',
    buttonExcluirCenario: 'Excluir o Cenário',

    buttonIncluirExclusaoFamiliar: 'Adicionar um Familiar a ser Desconsiderado',

    buttonIncluirExclusaoReceitaDespesa: 'Adicionar uma Receita/Despesa a ser Desconsiderada',

    buttonVisualizarSucessaoVitalicia: 'Sucessão Vitalícia',
    buttonVisualizarEstrategiasFinanceiras: 'Estratégias Financeiras',
    buttonVisualizarEvolucaoReservaFinanceira: 'Evolução da Reserva Financeira',
    buttonExcluirTabelaEvolucaoValorResgate: 'Excluir Tabela de Evolução do Valor de Resgate',
    buttonVisualizarTabelaEvolucaoValorResgate: 'Visualizar Tabela de Evolução do Valor de Resgate',
    buttonVisualizarDiagnosticoAposentadoriaIndependenciaFinanceira: 'Gráfico Diagnóstico ' + aposentadoria,
    buttonVisualizarComparacaoCenarios: 'Comparação de Cenários',
    buttonVisualizarComparacaoEstudos: 'Comparação de Estudos',
    buttonDetalhesCotacaoSeguroVidaSucessaoVitalicia: 'Cotação de Seguro de Vida para Sucessão Vitalícia',
    buttonDadosBasicos: 'Dados Básicos',
    buttonVisualizarGrafico: 'Visualizar Gráfico',
    buttonVisualizarTabela: 'Visualizar Tabela',
    buttonAlterarParametros: 'Alterar Parâmetros',
    buttonAlterarHipotesesFinanceiras: 'Alterar Hipóteses Financeiras',
    buttonIncluirAporteRetiradaMensal: 'Adicionar um Aporte/Retirada Mensal',
    buttonIncluirAporteRetiradaAnual: 'Adicionar um Aporte/Retirada Anual',


    buttonVisualizarGraficoMapaFinanceiro: 'Visualizar o Gráfico do Mapa Financeiro',



    buttonFundosPrevidenciaPrivada: 'Fundos Previdência',

    

    buttonVisualizarPDF: 'Visualizar o PDF',
    buttonVisualizarPDFInstitucional: 'Institucional',
    buttonVisualizarPDFTipoCarteiraInvestimento: 'Visualizar PDF do Tipo da Carteira',
    buttonVisualizarPDFTipoPoliticaInvestimento: 'Visualizar PDF da Política de Investimento',

    buttonReobterTemplate: 'Reobter o Template',


    buttonDetalhesCalculo: 'Detalhes do Cálculo',


    buttonAtualizarCamposCalculadosConfiguracaoNovaVersao: 'Atualizar campos calculados Configuração',
    buttonAtualizarCamposCalculadosClienteNovaVersao: 'Atualizar campos calculados Clientes',
    buttonAtualizarCamposCalculadosUsuarioNovaVersao: 'Atualizar campos calculados Usuários',

    buttonGerarCobranca: 'Gerar Cobrança',
    buttonEfetuarCobranca: 'Efetuar Cobrança',
    buttonAcompanharCobranca: 'Acompanhar Cobrança',

    buttonEnviarLembreteAporte: 'Enviar lembretes dos Aportes',


    buttonGerarPDF: 'Gerar PDF',
    buttonGerarPDFEnviarEmail: 'Gerar PDF / Enviar E-mail',


    buttonIncluirPendencia: 'Adicionar uma Pendência',



    buttonIncluirAnotacao: 'Adicionar uma Anotação',



    iconButtonEditar: 'Editar',
    iconButtonVerDetalhes: 'Ver Detalhes',
    iconButtonTransferir: 'Transferir',
    iconButtonClearEntradaDados: 'Excluir',
    iconButtonClearDialog: 'Fechar',
    iconButtonClear: 'Limpar',
    iconButtonVersao: 'Versões',
    iconButtonModoApresentacao: 'Modo Apresentação',
    modoApresentacaoPaginaAnterior: 'Página Anterior',
    modoApresentacaoProximaPagina: 'Próxima Página',
    iconButtonFiltrar: 'Filtro',
    iconButtonImprimir: 'Imprimir',
    iconButtonExpandMore: 'Expandir',
    iconButtonExpandMoreAll: 'Expandir Todos',
    iconButtonExpandLess: 'Minimizar',
    iconButtonExpandLessAll: 'Minimizar Todos',
    iconButtonWhatsapp: 'WhatsApp',



    confirmaExclusao: 'Confirma exclusão ?',
    sim: 'Sim',
    nao: 'Não',
    ok: 'OK',



    paginaAnterior: 'Página Anterior',
    proximaPagina: 'Próxima Página',
    zoomOut: 'Reduzir o Zoom',
    zoomIn: 'Aumentar Zoom',
    downloadPDF: 'Download PDF',
    imprimirPDF: 'Imprimir PDF',



    MUIDataTableNoMatch: 'Nenhum registro foi encontrado',
    MUIDataTableToolTip: 'Ordenação',
    MUIDataTableColumnHeaderTooltip: 'Ordenador por',
    MUIDataTablePaginationNext: 'Próxima Página',
    MUIDataTablePaginationPrevious: 'Página Anterior',
    MUIDataTablePaginationRowsPerPage: 'Linhas por Página:',
    MUIDataTablePaginationDisplayRows: 'de',
    MUIDataTablePaginationJumpToPage: 'Ir para página:',
    MUIDataTableToolbarSearch: 'Pesquisa',
    MUIDataTableToolbarDownloadCsv: 'Download CSV',
    MUIDataTableToolbarPrint: 'Imprimir',
    MUIDataTableToolbarViewColumns: 'Ver Colunas',
    MUIDataTableToolbarFilterTable: 'Filtrar',
    MUIDataTableFilterAll: 'Todos',
    MUIDataTableFilterTitle: 'Filtros',
    MUIDataTableFilterReset: 'Limpar',
    MUIDataTableViewColumnsTitle: 'Mostrar Colunas',
    MUIDataTableViewColumnsTitleAria: 'Mostrar/Esconder Colunas da Tabela',
    MUIDataTableSelectedRowsText: 'linha(s) selecionadas',
    MUIDataTableSelectedRowsDelete: 'Deletar',
    MUIDataTableSelectedRowseleteAria: 'Deleta Linhas Selecionadas',



    MultiSelectAllItemsAreSelected: "Todos $1$ foram selecionados",
    MultiSelectClearSearch: "Limpar Pesquisa",
    MultiSelectClearSelected: "Limpar Selecionados",
    MultiSelectNoOptions: "Nenhuma opção",
    MultiSelectSearch: "Pesquisar",
    MultiSelectSelectAll: "Selecionar Todos",
    MultiSelectSelectAllFiltered: "Selecionar Todos (Filtrados)",
    MultiSelectCreate: "Criar",



    impressao: '(impressão)',
}

export default LABEL