import HARDCODE from '../../business-const/HardCode/HardCode'

import LABEL from '../../business-const/Label/Label'

import {
    pesquisaList
} from '../../business-rules/List/ListService'

import formList from './formList'



const reportList = (idFormList, linhaTipoLicencaNULL, configuracaoPagina, jsonPaginaNULL, paginaAdicionalNULL) => {

    let reportListAux = []

    const linhaFormList = pesquisaList(formList, idFormList)

    if (linhaTipoLicencaNULL && linhaTipoLicencaNULL.exibirSomentePrevidenciaPrivada === 'S') {

        reportListAux.push(
            {
                id: HARDCODE.estudoFormCapa,
                descricao: LABEL.abertura + ': ' + LABEL.estudoReportCapa,
                content: '<b>' + LABEL.abertura + '</b>: ' + LABEL.estudoReportCapa,
                ////validaInformaMapaFinanceiro: 'N',
                imprimir: 'S',
            },
            {
                id: HARDCODE.estudoReportConferenciaDados,
                descricao: LABEL.abertura + ': ' + LABEL.conferenciaDados,
                content: '<b>' + LABEL.abertura + '</b>: ' + LABEL.conferenciaDados,
                ////validaInformaMapaFinanceiro: 'N',
                imprimir: 'S',
            },
            {
                id: HARDCODE.estudoFormObjetivos,
                descricao: LABEL.abertura + ': ' + LABEL.objetivosPrincipaisPreocupacoesPFCMLP,
                content: '<b>' + LABEL.abertura + '</b>: ' + LABEL.objetivosPrincipaisPreocupacoesPFCMLP,
                ////validaInformaMapaFinanceiro: 'N',
                imprimir: 'S',
            },
            {
                id: HARDCODE.previdenciaPrivadaFormPrevidenciaPrivada,
                descricao: LABEL.previdenciaPrivada + ': ' + LABEL.analiseBeneficiosPrevidenciaPrivada,
                content: '<b>' + LABEL.previdenciaPrivada + '</b>: ' + LABEL.analiseBeneficiosPrevidenciaPrivada,
                ////validaInformaMapaFinanceiro: 'N',
                imprimir: 'S',
            },
            {
                id: HARDCODE.estudoFormConsideracoesFinais,
                descricao: LABEL.conclusao + ': ' + linhaFormList.labelTabClienteFormListConsideracoesFinais,
                content: '<b>' + LABEL.conclusao + '</b>: ' + linhaFormList.labelTabClienteFormListConsideracoesFinais,
                ////validaInformaMapaFinanceiro: 'N',
                imprimir: 'S',
            },
        )

    } else {

        if (!configuracaoPagina) {

            reportListAux.push(
                {
                    id: HARDCODE.estudoFormCapa,
                    descricao: LABEL.abertura + ': ' + LABEL.estudoReportCapa,
                    content: '<b>' + LABEL.abertura + '</b>: ' + LABEL.estudoReportCapa,
                    ////validaInformaMapaFinanceiro: 'N',
                    imprimir: 'S',
                },
            )

            reportListAux.push(
                {
                    id: HARDCODE.estudoFormOQueEsperarDesseTrabalho,
                    descricao: LABEL.abertura + ': ' + LABEL.oQueEsperarDesseTrabalho,
                    content: '<b>' + LABEL.abertura + '</b>: ' + LABEL.oQueEsperarDesseTrabalho,
                    ////validaInformaMapaFinanceiro: 'N',
                    imprimir: 'S',
                },
            )

            //???if (linhaFormList.perguntasObjetivos === HARDCODE.perguntasObjetivosPerguntas) {
            //
            //    reportListAux.push(
            //        {
            //            id: ???'EstudoFormPerguntas',
            //            descricao: '<b>' + LABEL.abertura + '</b>: ' + LABEL.perguntas,
            //            content: '<b>' + LABEL.abertura + '</b>: ' + LABEL.perguntas,
            //            ////validaInformaMapaFinanceiro: 'N',
            //            imprimir: 'S',
            //        },
            //    )
            //}

            if (linhaFormList.perguntasObjetivos === HARDCODE.perguntasObjetivosObjetivos) {

                reportListAux.push(
                    {
                        id: HARDCODE.estudoFormObjetivos,
                        descricao: LABEL.abertura + ': ' + LABEL.objetivosPrincipaisPreocupacoesPFCMLP,
                        content: '<b>' + LABEL.abertura + '</b>: ' + LABEL.objetivosPrincipaisPreocupacoesPFCMLP,
                        ////validaInformaMapaFinanceiro: 'N',
                        imprimir: 'S',
                    },
                )
            }
        }

        reportListAux.push(
            {
                id: HARDCODE.estudoReportConferenciaDados,
                descricao: LABEL.abertura + ': ' + LABEL.conferenciaDados,
                content: '<b>' + LABEL.abertura + '</b>: ' + LABEL.conferenciaDados,
                ////validaInformaMapaFinanceiro: 'N',
                imprimir: 'S',
            },
        )

        reportListAux.push(
            {
                id: HARDCODE.estudoReportConferenciaDadosClienteConjugeFamiliares,
                descricao: LABEL.abertura + ': ' + LABEL.conferenciaDadosClienteConjugeFamiliares,
                content: '<b>' + LABEL.abertura + '</b>: ' + LABEL.conferenciaDadosClienteConjugeFamiliares,
                ////validaInformaMapaFinanceiro: 'N',
                imprimir: '-',
            },
        )

        reportListAux.push(
            {
                id: HARDCODE.estudoReportConferenciaDadosClienteConjugeFamiliaresObjetivos,
                descricao: LABEL.abertura + ': ' + LABEL.conferenciaDadosClienteConjugeFamiliaresObjetivos,
                content: '<b>' + LABEL.abertura + '</b>: ' + LABEL.conferenciaDadosClienteConjugeFamiliaresObjetivos,
                ////validaInformaMapaFinanceiro: 'N',
                imprimir: '-',
            },
        )

        reportListAux.push(
            {
                id: HARDCODE.estudoReportConferenciaDadosClienteConjugeFamiliaresTaxas,
                descricao: LABEL.abertura + ': ' + LABEL.conferenciaDadosClienteConjugeFamiliaresTaxas,
                content: '<b>' + LABEL.abertura + '</b>: ' + LABEL.conferenciaDadosClienteConjugeFamiliaresTaxas,
                ////validaInformaMapaFinanceiro: 'N',
                imprimir: '-',
            },
        )

        if (!linhaTipoLicencaNULL || (linhaTipoLicencaNULL && linhaTipoLicencaNULL.exibirAcumulacaoAposentadoriaProjetoVidaSonho === 'S')) {

            reportListAux.push(
                {
                    id: HARDCODE.estudoFormPlanejamentoEspecializacaoPeriodoSabaticoResumo,
                    descricao: LABEL.abertura + ': ' + LABEL.planejamentoEspecializacaoPeriodoSabatico,
                    content: '<b>' + LABEL.abertura + '</b>: ' + LABEL.planejamentoEspecializacaoPeriodoSabatico,
                    ////validaInformaMapaFinanceiro: 'N',
                    imprimir: 'S',
                },
            )
        }

        //if (
        //        !linhaTipoLicencaNULL ||
        //        (linhaTipoLicencaNULL && linhaTipoLicencaNULL.exibirAcumulacaoAposentadoriaProjetoVidaSonho === 'S') ||
        //        linhaFormList.exibirGestaoPatrimonial === "S"
        //) {
            
        if (!configuracaoPagina) {
            
            reportListAux.push(
                {
                    id: HARDCODE.gestaoPatrimonialFormEstruturaFamiliar,
                    descricao: LABEL.gestaoPatrimonial + ': ' + LABEL.estruturaFamiliar,
                    content: '<b>' + LABEL.gestaoPatrimonial + '</b>: ' + LABEL.estruturaFamiliar,
                    ////validaInformaMapaFinanceiro: 'N',
                    imprimir: '-',
                },
            )
        }

        reportListAux.push(
            {
                id: HARDCODE.carteiraInvestimentosChart,
                descricao: LABEL.gestaoPatrimonial + ': ' + LABEL.carteiraInvestimentos,
                content: '<b>' + LABEL.gestaoPatrimonial + '</b>: ' + LABEL.carteiraInvestimentos,
                ////validaInformaMapaFinanceiro: 'N',
                imprimir: 'N',
            },
        )

        if (!configuracaoPagina) {
            
            reportListAux.push(
                {
                    id: HARDCODE.estudoFormTabelaEvolucaoValorResgate,
                    descricao: LABEL.gestaoPatrimonial + ': ' + LABEL.tabelaEvolucaoValorResgate,
                    content: '<b>' + LABEL.gestaoPatrimonial + '</b>: ' + LABEL.tabelaEvolucaoValorResgate,
                    ////validaInformaMapaFinanceiro: 'N',
                    imprimir: '-',
                },
            )

            reportListAux.push(
                {
                    id: HARDCODE.estudoFormTabelaEvolucaoValorResgateComparacaoPreco,
                    descricao: LABEL.gestaoPatrimonial + ': ' + LABEL.tabelaEvolucaoValorResgate + ' - ' + LABEL.comparacaoPreco,
                    content: '<b>' + LABEL.gestaoPatrimonial + '</b>: ' + LABEL.tabelaEvolucaoValorResgate + ' - ' + LABEL.comparacaoPreco,
                    ////validaInformaMapaFinanceiro: 'N',
                    imprimir: '-',
                },
            )
        }

        reportListAux.push(
            {
                id: HARDCODE.analisePatrimonialChart,
                descricao: LABEL.aposentadoria + ': ' + LABEL.grafico + ' ' + LABEL.analisePatrimonial,
                content: '<b>' + LABEL.aposentadoria + '</b>: ' + LABEL.grafico + ' ' + LABEL.analisePatrimonial,
                ////validaInformaMapaFinanceiro: 'N',
                imprimir: 'S',
            },
            {
                id: HARDCODE.receitaAnualChart,
                descricao: LABEL.aposentadoria + ': ' + LABEL.grafico + ' ' + LABEL.receitaAnualLongoTempo,
                content: '<b>' + LABEL.aposentadoria + '</b>: ' + LABEL.grafico + ' ' + LABEL.receitaAnualLongoTempo,
                ////validaInformaMapaFinanceiro: 'N',
                imprimir: 'S',
            },
            {
                id: HARDCODE.despesaAnualChart,
                descricao: LABEL.aposentadoria + ': ' + LABEL.grafico + ' ' + LABEL.despesaAnualLongoTempo,
                content: '<b>' + LABEL.aposentadoria + '</b>: ' + LABEL.grafico + ' ' + LABEL.despesaAnualLongoTempo,
                ////validaInformaMapaFinanceiro: 'N',
                imprimir: 'S',
            },
            {
                id: HARDCODE.balancoReceitaXDespesaAnualChart,
                descricao: LABEL.aposentadoria + ': ' + LABEL.grafico + ' ' + LABEL.balancoReceitaDespesaAnual,
                content: '<b>' + LABEL.aposentadoria + '</b>: ' + LABEL.grafico + ' ' + LABEL.balancoReceitaDespesaAnual,
                ////validaInformaMapaFinanceiro: 'N',
                imprimir: 'S',
            },
        )
        //}

        if (!linhaTipoLicencaNULL || (linhaTipoLicencaNULL && linhaTipoLicencaNULL.exibirAcumulacaoAposentadoriaProjetoVidaSonho === 'S')) {
            
            reportListAux.push(
                {
                    id: HARDCODE.consumoReservaAposentadoriaChart,
                    descricao: LABEL.aposentadoria + ': ' + LABEL.grafico + ' ' + LABEL.consumoReservaAposentadoria,
                    content: '<b>' + LABEL.aposentadoria + '</b>: ' + LABEL.grafico + ' ' + LABEL.consumoReservaAposentadoria,
                    ////validaInformaMapaFinanceiro: 'N',
                    imprimir: 'S',
                },
                {
                    id: HARDCODE.diagnosticoAposentadoriaChart,
                    descricao: LABEL.aposentadoria + ': ' + LABEL.grafico + ' ' + LABEL.diagnosticoAposentadoria,
                    content: '<b>' + LABEL.aposentadoria + '</b>: ' + LABEL.grafico + ' ' + LABEL.diagnosticoAposentadoria,
                    ////validaInformaMapaFinanceiro: 'N',
                    imprimir: 'S',
                },
                {
                    id: HARDCODE.diagnosticoAposentadoriaIndependenciaFinanceiraChart,
                    descricao: LABEL.aposentadoria + ': ' + LABEL.grafico + ' ' + LABEL.diagnosticoAposentadoriaIndependenciaFinanceira,
                    content: '<b>' + LABEL.aposentadoria + '</b>: ' + LABEL.grafico + ' ' + LABEL.diagnosticoAposentadoriaIndependenciaFinanceira,
                    ////validaInformaMapaFinanceiro: 'N',
                    imprimir: 'S',
                },
            )
        }
        
        if (!configuracaoPagina) {

            if (!linhaTipoLicencaNULL || (linhaTipoLicencaNULL && linhaTipoLicencaNULL.exibirAcumulacaoAposentadoriaProjetoVidaSonho === 'S')) {

                reportListAux.push(
                    {
                        id: HARDCODE.estudoFormEvolucaoReservaFinanceiraEstudo,
                        descricao: LABEL.aposentadoria + ': ' + LABEL.evolucaoReservaFinanceira,
                        content: '<b>' + LABEL.aposentadoria + '</b>: ' + LABEL.evolucaoReservaFinanceira,
                        ////validaInformaMapaFinanceiro: 'N',
                        imprimir: '-',
                    },
                )
            }
        }

        if (!linhaTipoLicencaNULL || (linhaTipoLicencaNULL && linhaTipoLicencaNULL.exibirAcumulacaoAposentadoriaProjetoVidaSonho === 'S')) {
            
            reportListAux.push(
                {
                    id: HARDCODE.estudoFormProjetoVidaSonhoTracarMeta,
                    descricao: LABEL.projetoVidaSonho + ': ' + LABEL.projetoVidaSonhoTracarMeta,
                    content: '<b>' + LABEL.projetoVidaSonho + '</b>: ' + LABEL.projetoVidaSonhoTracarMeta,
                    ////validaInformaMapaFinanceiro: 'N',
                    imprimir: 'S',
                },
            )

            reportListAux.push(
                {
                    id: HARDCODE.previdenciaPrivadaFormPrevidenciaPrivada,
                    descricao: LABEL.previdenciaPrivada + ': ' + LABEL.analiseBeneficiosPrevidenciaPrivada,
                    content: '<b>' + LABEL.previdenciaPrivada + '</b>: ' + LABEL.analiseBeneficiosPrevidenciaPrivada,
                    ////validaInformaMapaFinanceiro: 'N',
                    imprimir: 'N',
                },
            )
        }

        reportListAux.push(
            {
                id: HARDCODE.estudoFormProtecaoSugerida,
                descricao: LABEL.protecao + ': ' + LABEL.protecaoSugerida,
                content: '<b>' + LABEL.protecao + '</b>: ' + LABEL.protecaoSugerida,
                ////validaInformaMapaFinanceiro: 'N',
                imprimir: 'S',
            },
            {
                id: HARDCODE.estudoFormSucessaoVitalicia,
                descricao: LABEL.protecao + ': ' + LABEL.sucessaoVitalicia,
                content: '<b>' + LABEL.protecao + '</b>: ' + LABEL.sucessaoVitalicia,
                ////validaInformaMapaFinanceiro: 'N',
                imprimir: 'S',
            },
            {
                id: HARDCODE.estudoFormComposicaoInventario,
                descricao: LABEL.protecao + ': ' + LABEL.composicaoInventario,
                content: '<b>' + LABEL.protecao + '</b>: ' + LABEL.composicaoInventario,
                ////validaInformaMapaFinanceiro: 'N',
                imprimir: 'N',
            },
            {
                id: HARDCODE.projecaoSucessaoVitaliciaChart,
                descricao: LABEL.protecao + ': ' + LABEL.projecaoSucessaoVitalicia,
                content: '<b>' + LABEL.protecao + '</b>: ' + LABEL.projecaoSucessaoVitalicia,
                ////validaInformaMapaFinanceiro: 'N',
                imprimir: 'S',
            },
            {
                id: HARDCODE.despesasAcumuladasChart,
                descricao: LABEL.protecao + ': ' + LABEL.grafico + ' ' + LABEL.despesasAcumuladasLongoTempo,
                content: '<b>' + LABEL.protecao + '</b>: ' + LABEL.grafico + ' ' + LABEL.despesasAcumuladasLongoTempo,
                ////validaInformaMapaFinanceiro: 'N',
                imprimir: 'S',
            },
            {
                id: HARDCODE.estudoReportResumoNecessidadeProtecao,
                descricao: LABEL.protecao + ': ' + LABEL.necessidadeProtecao,
                content: '<b>' + LABEL.protecao + '</b>: ' + LABEL.necessidadeProtecao,
                ////validaInformaMapaFinanceiro: 'N',
                imprimir: 'S',
            },
            {
                id: HARDCODE.estudoFormDespesasDiaDiaDespesasVitalicias,
                descricao: LABEL.despesasTotais + ': ' + LABEL.diaDiaDespesasVitalicias,
                content: '<b>' + LABEL.despesasTotais + '</b>: ' + LABEL.diaDiaDespesasVitalicias,
                ////validaInformaMapaFinanceiro: 'N',
                imprimir: 'N',
            },
            {
                id: HARDCODE.estudoFormDespesasEducacao,
                descricao: LABEL.despesasTotais + ': ' + LABEL.educacao,
                content: '<b>' + LABEL.despesasTotais + '</b>: ' + LABEL.educacao,
                ////validaInformaMapaFinanceiro: 'N',
                imprimir: 'N',
            },
            {
                id: HARDCODE.estudoFormDespesasFinanciamento,
                descricao: LABEL.despesasTotais + ': ' + LABEL.financiamento,
                content: '<b>' + LABEL.despesasTotais + '</b>: ' + LABEL.financiamento,
                ////validaInformaMapaFinanceiro: 'N',
                imprimir: 'N',
            },
            {
                id: HARDCODE.estudoFormDespesasDiaDiaDespesasTemporarias,
                descricao: LABEL.despesasTotais + ': ' + LABEL.diaDiaDespesasTemporarias,
                content: '<b>' + LABEL.despesasTotais + '</b>: ' + LABEL.diaDiaDespesasTemporarias,
                ////validaInformaMapaFinanceiro: 'N',
                imprimir: 'N',
            },
            {
                id: HARDCODE.estudoFormProjetoVidaSonho,
                descricao: LABEL.despesasTotais + ': ' + LABEL.projetoVidaSonho,
                content: '<b>' + LABEL.despesasTotais + '</b>: ' + LABEL.projetoVidaSonho,
                ////validaInformaMapaFinanceiro: 'N',
                imprimir: 'N',
            },
            {
                id: HARDCODE.estudoFormGarantiasPatrimonioImobilizado,
                descricao: LABEL.garantiasAtuais + ': ' + LABEL.patrimonioImobilizado,
                content: '<b>' + LABEL.garantiasAtuais + '</b>: ' + LABEL.patrimonioImobilizado,
                ////validaInformaMapaFinanceiro: 'N',
                imprimir: 'N',
            },
            {
                id: HARDCODE.estudoFormGarantiasReceitaMensal,
                descricao: LABEL.garantiasAtuais + '>: ' + LABEL.receitaMensal,
                content: '<b>' + LABEL.garantiasAtuais + '</b>: ' + LABEL.receitaMensal,
                ////validaInformaMapaFinanceiro: 'N',
                imprimir: 'N',
            },
            {
                id: HARDCODE.estudoFormGarantiasRecursosFinanceiros,
                descricao: LABEL.garantiasAtuais + ': ' + LABEL.recursosFinanceiros,
                content: '<b>' + LABEL.garantiasAtuais + '</b>: ' + LABEL.recursosFinanceiros,
                ////validaInformaMapaFinanceiro: 'N',
                imprimir: 'N',
            },
            {
                id: HARDCODE.estudoFormSeguroVida,
                descricao: LABEL.garantiasAtuais + ': ' + LABEL.seguroVida,
                content: '<b>' + LABEL.garantiasAtuais + '</b>: ' + LABEL.seguroVida,
                ////validaInformaMapaFinanceiro: 'N',
                imprimir: 'N',
            },
        )

        if (!configuracaoPagina) {
        
            if (
                    !linhaTipoLicencaNULL ||
                    (
                        linhaTipoLicencaNULL &&
                        linhaTipoLicencaNULL.exibirSimulacaoFinanceira === 'S'
                    )
            ) {

                reportListAux.push(
                    {
                        id: HARDCODE.simulacaoFinanceiraFormEvolucaoReservaFinanceira,
                        descricao: LABEL.simulacaoFinanceira + ': ' + LABEL.evolucaoReservaFinanceira,
                        content: '<b>' + LABEL.simulacaoFinanceira + '</b>: ' + LABEL.evolucaoReservaFinanceira,
                        ////validaInformaMapaFinanceiro: 'N',
                        imprimir: '-',
                    },
                )
            }
        }

        if (
                (
                    !linhaTipoLicencaNULL ||
                    (
                        linhaTipoLicencaNULL && 
                        linhaTipoLicencaNULL.exibirSimulacaoFinanceira === 'S'
                    )
                ) &&
                linhaFormList.exibirMapaFinanceiro === 'S' &&
                linhaFormList.reportPrimeiraReuniaoInvestimentoSeguroVida === "N"
        ) {

            reportListAux.push(
                {
                    id: HARDCODE.mapaFinanceiroFormGrafico,
                    descricao: LABEL.mapaFinanceiro + ': ' + LABEL.grafico,
                    content: '<b>' + LABEL.mapaFinanceiro + '</b>: ' + LABEL.grafico,
                    validaInformaMapaFinanceiro: 'S',
                    imprimir: 'N',
                },
            )
        }

        if (!linhaTipoLicencaNULL || (linhaTipoLicencaNULL && linhaTipoLicencaNULL.exibirAcumulacaoAposentadoriaProjetoVidaSonho === 'S')) {

            reportListAux.push(
                {
                    id: HARDCODE.estudoFormComparacaoCenariosGrafico,
                    descricao: LABEL.cenarios + ': ' + LABEL.comparacaoCenarios + ' (' + LABEL.grafico + ')',
                    content: '<b>' + LABEL.cenarios + '</b>: ' + LABEL.comparacaoCenarios + ' (' + LABEL.grafico + ')',
                    ////validaInformaMapaFinanceiro: 'N',
                    imprimir: 'N',
                },
                {
                    id: HARDCODE.estudoFormComparacaoCenarios,
                    descricao: LABEL.cenarios + ': ' + LABEL.comparacaoCenarios,
                    content: '<b>' + LABEL.cenarios + '</b>: ' + LABEL.comparacaoCenarios,
                    ////validaInformaMapaFinanceiro: 'N',
                    imprimir: 'N',
                },
                {
                    id: HARDCODE.estudoFormComparacaoEstudosGrafico,
                    descricao: LABEL.estudos + ': ' + LABEL.comparacaoEstudos + ' (' + LABEL.grafico + ')',
                    content: '<b>' + LABEL.estudos + '</b>: ' + LABEL.comparacaoEstudos + ' (' + LABEL.grafico + ')',
                    ////validaInformaMapaFinanceiro: 'N',
                    imprimir: 'N',
                },
                {
                    id: HARDCODE.estudoFormComparacaoEstudos,
                    descricao: LABEL.estudos + ': ' + LABEL.comparacaoEstudos,
                    content: '<b>' + LABEL.estudos + '</b>: ' + LABEL.comparacaoEstudos,
                    ////validaInformaMapaFinanceiro: 'N',
                    imprimir: 'N',
                },
            )
        }

        if (!configuracaoPagina) {

            reportListAux.push(
                {
                    id: HARDCODE.estudoFormConsideracoesFinais,
                    descricao: LABEL.conclusao + ': ' + linhaFormList.labelTabClienteFormListConsideracoesFinais,
                    content: '<b>' + LABEL.conclusao + '</b>: ' + linhaFormList.labelTabClienteFormListConsideracoesFinais,
                    ////validaInformaMapaFinanceiro: 'N',
                    imprimir: 'S',
                },
            )
        }

        if (jsonPaginaNULL && jsonPaginaNULL.configuracaoPagina && jsonPaginaNULL.configuracaoPagina.length > 0) {

            jsonPaginaNULL.configuracaoPagina?.forEach( (linhaConfiguracaoPagina, indexConfiguracaoPagina) => {

                reportListAux.push(
                    {
                        id: HARDCODE.estudoFormPagina + linhaConfiguracaoPagina.id,
                        //descricao: linhaConfiguracaoPagina.descricaoPagina,
                        content: '<b>' + LABEL.pagina + '</b>: ' + linhaConfiguracaoPagina.descricaoPagina,
                        ////validaInformaMapaFinanceiro: 'N',
                        especial: true,
                        imprimir: '-',
                    },
                )
            })
        }

        if (paginaAdicionalNULL && paginaAdicionalNULL.length > 0) {

            paginaAdicionalNULL?.forEach( (linhaPaginaAdicional, indexPaginaAdicional) => {

                reportListAux.push(
                    {
                        id: HARDCODE.estudoFormPagina + linhaPaginaAdicional.id,
                        //descricao: linhaConfiguracaoPagina.descricaoPagina,
                        content: '<b>' + LABEL.paginaAdicional + '</b>: ' + linhaPaginaAdicional.descricaoPagina,
                        ////validaInformaMapaFinanceiro: 'N',
                        especial: true,
                        imprimir: '-',
                    },
                )
            })
        }
    }

    return reportListAux
}

export default reportList