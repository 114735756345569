const pessoaFisicaJuridicaList = [
    {
       id: 'F',
       descricao: 'Pessoa Física',
       ordem: 10,
    },
    {
       id:  'J',
       descricao: 'Pessoa Jurídica',
       ordem: 20,
    },
 ]
 
 export default pessoaFisicaJuridicaList