import HARDCODE from '../../../business-const/HardCode/HardCode'

import {
    ano,
    mes,
    dia,
    dataAnoMesDia,
} from '../../../business-components/Date/Date'

import {
    round
} from '../../../business-components/round/round'

import {
    indexAtualJsonRegistros,
    obtemDadoXLSX,
    agentesSeguradora,
    agentePertenceSeguradora,
    jsonRegistrosInicializacao,
    jsonRegistrosJsonRegistrosInicializacao,
    jsonRegistrosJsonRegistrosAtualizaErros,
    incluiCoberturas,
} from './IntegracaoClienteArquivoService'



export const importaListaApolicesMAPFRE = (XLSX, idUsuarioLogado, jsonUsuario, equipeList, linhaIntegracao, seguradora,
    nomeArquivo, erros) => {

    let jsonRegistros = jsonRegistrosInicializacao()

    let linha = linhaIntegracao.cabecalho + 1

    const agentesSeguradoraAux = agentesSeguradora(jsonUsuario, seguradora)

    while (linha < XLSX.length) {

        const agenteOK = agentePertenceSeguradora(agentesSeguradoraAux, XLSX[linha][1])

        if (agenteOK && XLSX[linha][2]) {

            jsonRegistros.jsonRegistros.push(jsonRegistrosJsonRegistrosInicializacao(erros))

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 2, HARDCODE.importacaoNumeroApolice, true, null, erros, nomeArquivo)
            
            obtemDadoXLSX(XLSX, jsonRegistros, linha, 8, HARDCODE.importacaoStatusApolice, true, linhaIntegracao.statusApolice,
                erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 3, HARDCODE.importacaoNumeroCotacao, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 7, HARDCODE.importacaoDataEmissao, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 4, HARDCODE.importacaoNomeSeguradoCliente, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 5, HARDCODE.importacaoCpfSeguradoCliente, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 33, HARDCODE.importacaoProfissaoSegurado, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 23, HARDCODE.importacaoNascimentoSeguradoCliente, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 21, HARDCODE.importacaoSexoSeguradoCliente, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 44, HARDCODE.importacaoEnderecoSeguradoCliente, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 45, HARDCODE.importacaoNumeroEnderecoSeguradoCliente, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 46, HARDCODE.importacaoComplementoEnderecoSeguradoCliente, false, null,
                erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 47, HARDCODE.importacaoBairroSeguradoCliente, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 48, HARDCODE.importacaoCidadeSeguradoCliente, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 49, HARDCODE.importacaoUfSeguradoCliente, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 50, HARDCODE.importacaoCepSeguradoCliente, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 34, HARDCODE.importacaoTelefoneSegurado, false, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 35, HARDCODE.importacaoTelefoneComercialSegurado, false, null,
                erros, nomeArquivo)
            
            obtemDadoXLSX(XLSX, jsonRegistros, linha, 36, HARDCODE.importacaoCelularSeguradoCliente, false, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 54, HARDCODE.importacaoEmailSeguradoCliente, false, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 28, HARDCODE.importacaoTipoPeriodicidadePagtoPremio, true, null, erros, nomeArquivo)



            const indexJsonRegistros = indexAtualJsonRegistros(jsonRegistros)

            const coberturas = []

            let dataTerminoCobertura

            const statusCobertura =
                jsonRegistros.jsonRegistros[indexJsonRegistros].statusApolice === HARDCODE.idStatusApoliceAtiva
                    ?
                        HARDCODE.idStatusCoberturaAtivaPagandoPremio
                    :
                        HARDCODE.idStatusCoberturaCancelada

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 29, HARDCODE.importacaoDescricaoCobertura, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 22, HARDCODE.importacaoClasseAjusteRisco, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 9, HARDCODE.importacaoValorCS, false, null, erros, nomeArquivo)

            if (jsonRegistros.jsonRegistros[indexJsonRegistros].valorCS) {

                obtemDadoXLSX(XLSX, jsonRegistros, linha, 10, HARDCODE.importacaoValorPremio, true, null, erros, nomeArquivo)

                obtemDadoXLSX(XLSX, jsonRegistros, linha, 11, HARDCODE.importacaoPrazoCobertura, true, null, erros, nomeArquivo)

                const anoEmissao = ano(jsonRegistros.jsonRegistros[indexJsonRegistros].dataEmissao)
                const mesEmissao = mes(jsonRegistros.jsonRegistros[indexJsonRegistros].dataEmissao)
                const diaEmissao = dia(jsonRegistros.jsonRegistros[indexJsonRegistros].dataEmissao)

                dataTerminoCobertura =
                    dataAnoMesDia(anoEmissao + jsonRegistros.jsonRegistros[indexJsonRegistros].prazoCobertura - 1, mesEmissao, diaEmissao)

                incluiCoberturas(coberturas, null, null, jsonRegistros.jsonRegistros[indexJsonRegistros].descricaoCobertura,
                    HARDCODE.idTipoCoberturaSeguroVidaDotalMisto, statusCobertura, jsonRegistros.jsonRegistros[indexJsonRegistros].valorCS,
                        null, null, jsonRegistros.jsonRegistros[indexJsonRegistros].valorPremio,
                            jsonRegistros.jsonRegistros[indexJsonRegistros].classeAjusteRisco, null, null, dataTerminoCobertura, null)



                obtemDadoXLSX(XLSX, jsonRegistros, linha, 12, HARDCODE.importacaoValorCS, false, null, erros, nomeArquivo)

                if (jsonRegistros.jsonRegistros[indexJsonRegistros].valorCS) {

                    obtemDadoXLSX(XLSX, jsonRegistros, linha, 13, HARDCODE.importacaoValorPremio, true, null, erros, nomeArquivo)

                    coberturas[0].valorCSSobrevivencia = jsonRegistros.jsonRegistros[indexJsonRegistros].valorCS
                    
                    coberturas[0].valorPremio = round(coberturas[0].valorPremio + jsonRegistros.jsonRegistros[indexJsonRegistros].valorPremio, 2)
                }



                obtemDadoXLSX(XLSX, jsonRegistros, linha, 15, HARDCODE.importacaoValorCS, false, null, erros, nomeArquivo)

                if (jsonRegistros.jsonRegistros[indexJsonRegistros].valorCS) {
    
                    obtemDadoXLSX(XLSX, jsonRegistros, linha, 16, HARDCODE.importacaoValorPremio, true, null, erros, nomeArquivo)
    
                    obtemDadoXLSX(XLSX, jsonRegistros, linha, 17, HARDCODE.importacaoPrazoCobertura, true, null, erros, nomeArquivo)
    
                    dataTerminoCobertura =
                        dataAnoMesDia(anoEmissao + jsonRegistros.jsonRegistros[indexJsonRegistros].prazoCobertura - 1, mesEmissao, diaEmissao)
    
                    incluiCoberturas(coberturas, null, null, jsonRegistros.jsonRegistros[indexJsonRegistros].descricaoCobertura,
                        HARDCODE.idTipoCoberturaSeguroVidaInvalidezPorAcidenteTotalParcial, statusCobertura,
                            jsonRegistros.jsonRegistros[indexJsonRegistros].valorCS, null, null,
                                jsonRegistros.jsonRegistros[indexJsonRegistros].valorPremio,
                                    jsonRegistros.jsonRegistros[indexJsonRegistros].classeAjusteRisco, null, null, dataTerminoCobertura, null)
                }
                


                obtemDadoXLSX(XLSX, jsonRegistros, linha, 18, HARDCODE.importacaoValorCS, false, null, erros, nomeArquivo)

                if (jsonRegistros.jsonRegistros[indexJsonRegistros].valorCS) {
    
                    obtemDadoXLSX(XLSX, jsonRegistros, linha, 19, HARDCODE.importacaoValorPremio, true, null, erros, nomeArquivo)
    
                    obtemDadoXLSX(XLSX, jsonRegistros, linha, 20, HARDCODE.importacaoPrazoCobertura, true, null, erros, nomeArquivo)
    
                    dataTerminoCobertura =
                        dataAnoMesDia(anoEmissao + jsonRegistros.jsonRegistros[indexJsonRegistros].prazoCobertura - 1, mesEmissao, diaEmissao)
    
                    incluiCoberturas(coberturas, null, null, jsonRegistros.jsonRegistros[indexJsonRegistros].descricaoCobertura,
                        HARDCODE.idTipoCoberturaSeguroVidaDoencasGraves, statusCobertura, jsonRegistros.jsonRegistros[indexJsonRegistros].valorCS,
                            null, null, jsonRegistros.jsonRegistros[indexJsonRegistros].valorPremio,
                                jsonRegistros.jsonRegistros[indexJsonRegistros].classeAjusteRisco, null, null, dataTerminoCobertura, null)
                }
            }

            jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas = coberturas

            jsonRegistros.jsonRegistros[indexJsonRegistros].integracaoSeguradora = true
        }
       
        linha++

        while (linha < XLSX.length && XLSX[linha - 1][2] === XLSX[linha][2]) {

            linha++
        }

        if (agenteOK) {

            jsonRegistrosJsonRegistrosAtualizaErros(jsonRegistros, erros)
        }
    }

    return jsonRegistros.jsonRegistros
}



export const importaListaCobrancasMAPFRE = (XLSX, idUsuarioLogado, jsonUsuario, equipeList, linhaIntegracao, seguradora,
    nomeArquivo, erros) => {

    let jsonRegistros = jsonRegistrosInicializacao()

    let linha = linhaIntegracao.cabecalho + 1

    const agentesSeguradoraAux = agentesSeguradora(jsonUsuario, seguradora)

    while (linha < XLSX.length) {

        const agenteOK = agentePertenceSeguradora(agentesSeguradoraAux, XLSX[linha][1])

        if (agenteOK && XLSX[linha][2]) {

            jsonRegistros.jsonRegistros.push(jsonRegistrosJsonRegistrosInicializacao(erros))

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 2, HARDCODE.importacaoNumeroApolice, true, null, erros, nomeArquivo)
            
            obtemDadoXLSX(XLSX, jsonRegistros, linha, 7, HARDCODE.importacaoTipoFormaPagtoPremio, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 8, HARDCODE.importacaoBanco, false, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 9, HARDCODE.importacaoAgencia, false, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 10, HARDCODE.importacaoConta, false, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 12, HARDCODE.importacaoDiaVencimento, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 13, HARDCODE.importacaoNumeroParcela, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 14, HARDCODE.importacaoStatusParcela, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 16, HARDCODE.importacaoTipoBaixaParcela, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 17, HARDCODE.importacaoDataVencimento, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 18, HARDCODE.importacaoDataCompetencia, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 19, HARDCODE.importacaoDataInicioVigencia, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 20, HARDCODE.importacaoDataFimVigencia, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 21, HARDCODE.importacaoDataPagamento, false, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 22, HARDCODE.importacaoDataCorte, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 23, HARDCODE.importacaoValorAPagar, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 24, HARDCODE.importacaoValorPago, false, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 25, HARDCODE.importacaoTipoPeriodicidadePagtoPremio, true, null, erros, nomeArquivo)

        }

        linha++

        if (agenteOK) {

            jsonRegistrosJsonRegistrosAtualizaErros(jsonRegistros, erros)
        }
    }

    return jsonRegistros.jsonRegistros
}