import HARDCODE from '../../../business-const/HardCode/HardCode'

import MESSAGE from '../../../business-const/Message/Message'

import {
    ano,
    mes,
    dia,
    dataAnoMesDia,
    stringParaData,
} from '../../../business-components/Date/Date'

import {
    round
} from '../../../business-components/round/round'

import {
    semAcento
} from '../../../business-components/String/String'

import {
    pesquisaList,
    pesquisaIdList,
    pesquisaChaveList,
    pesquisaAutoCompleteList,
} from '../../../business-rules/List/ListService'

import {
    inicializaAjustaInclusoesAlteracoesExclusoesJsonInfoclient,
    inicializaControleVersao,
} from './ClienteService'

import {
    calculaTotaisReceitaMensal,
} from '../garantias-form/GarantiasServiceReceitaMensal'

import {
    calculaTotaisRecursosFinanceiros,
} from '../garantias-form/GarantiasServiceRecursosFinanceiros'

import {
    processaCamposCalculados
} from '../estudo-form/EstudoServiceCalculo'



export const trataTexto = (valor) => {

    //try {

    if (valor) {

        valor = ('' + valor).toUpperCase().trim()
    }

    //} catch (erro) {

    //    valor = valor + ''
    //}

    return valor
}

export const trataValorTexto = (valor) => {

    if (valor === '-') {

        valor = ''

    } else {

        try {

            valor = valor.replaceAll(".", "").replace(",", ".").replace("R$", "")

            valor = parseFloat(valor)

        } catch (erro) {

            valor = round(valor, 2)
        } 
    }

    return valor
}

export const trataPercentualTexto = (valor) => {

    if (valor === '-') {

        valor = ''

    } else {

        valor = valor.replaceAll(".", "").replace(",", ".").replace("%", "")

        valor = parseFloat(valor)
    }

    return valor
}

export const trataInteiro = (valor) => {

    valor = Number.parseInt('' + valor)

    return valor
}

const trataCep = (valor) => {

    valor = ('' + valor).replace(HARDCODE.regExpFormataCep, '').replace('–', '')

    valor = ("00000000" + valor).slice(-8)

    return valor
}

const trataCpf = (valor) => {

    valor = ('' + valor).replace(HARDCODE.regExpFormataCpf, '')

    valor = ("000000000000" + valor).slice(-11)

    return valor
}

const trataCpfCnpj = (valor) => {

    valor = ('' + valor).replace(HARDCODE.regExpFormataCpfCnpj, '')

    valor = ("000000000000" + valor).slice(valor.length > 11 ? -14 : -11)

    return valor
}

export const trataTelefone = (valor) => {

    valor = '' + valor

    const barra = valor.indexOf('/')

    if (barra !== -1) {

        valor = valor.substr(0, barra)
    }

    const rponto = valor.indexOf('R')

    if (rponto !== -1) {

        valor = valor.substr(0, rponto)
    }

    valor = valor.replace(HARDCODE.regExpFormataTelefone, '')
    
    if (valor.substr(0, 1) === '0') {

        valor = valor.substr(1)
    }

    if (/*valor.substr(0, 2) === '55' &&*/ valor.length > 11) {
            
        valor = valor.substr(2)
    }

    return valor
}

const trataEmail = (valor) => {

    valor = valor.toLowerCase().trim()

    return valor
}

export const trataData = (valor) => {

    try {

        valor = new Date(valor.toUTCString().substr(5, 11))

    } catch (erro) {

        try {
            if (valor.substring(4, 5) === '-') {

                valor = valor.substring(8, 10) + '/' + valor.substring(5, 7) + '/' + valor.substring(0, 4)
            }

            valor = stringParaData(valor, 'dd/mm/yyyy')

        } catch (erro) {

            valor = new Date(Math.round((valor - 25569 + 1/*teve que adicionar 1 na fórmula original*/) * 86400 * 1000))

            valor = dataAnoMesDia(ano(valor), mes(valor), dia(valor))
        }
    }

    return valor
}

const trataValor = ( valor, type, tammax ) => {

    if (type === HARDCODE.typeString) {

        valor = trataTexto(valor)

        valor = valor.substring(0, tammax)

    } else {

        if (type === HARDCODE.typeValor) {

            if (('' + valor).indexOf(',') !== -1 || ('' + valor).indexOf('R$') !== -1) {

                valor = trataValorTexto(valor)
            }

        } else {

            if (type === HARDCODE.typePercentual) {

                if (('' + valor).indexOf(',') !== -1 || ('' + valor).indexOf('%') !== -1 || typeof valor === "string") {
    
                    valor = trataPercentualTexto(valor)
                }

            } else {
    
                if (type === HARDCODE.typeInteiro) {

                    valor = trataInteiro(valor)
        
                } else {

                    if (type === HARDCODE.typeCep) {

                        valor = trataCep(valor)

                    } else {
                        
                        if (type === HARDCODE.typeCpf) {

                            valor = trataCpf(valor)
                
                        } else {

                            if (type === HARDCODE.typeCpfCnpj) {

                                valor = trataCpfCnpj(valor)
                    
                            } else {

                                if (type === HARDCODE.typeTelefone) {

                                    valor = trataTelefone(valor)

                                } else {

                                    if (type === HARDCODE.typeEmail) {

                                        valor = trataEmail(valor)
        
                                    } else {

                                        if (type === HARDCODE.typeData) {

                                            valor = trataData(valor)
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    return valor
}

const validaValor = ( campo, valor, type, erros, linhaIdentificacao, XLSXPDF ) => {

    let valido = true

    if (valor) {

        if (
                type === HARDCODE.typeInteiro ||
                type === HARDCODE.typeCep ||
                type === HARDCODE.typeCpf ||
                type === HARDCODE.typeCpfCnpj ||
                type === HARDCODE.typeTelefone
        ) {

            if (!HARDCODE.regExpSomenteNumero.test(valor)) {

                valido = false
            }

        } else {

            if (type === HARDCODE.typeValor) {

                if (!HARDCODE.regExpValidaValor.test(valor)) {

                    valido = false
                }

            } else {

                if (type === HARDCODE.typePercentual) {

                    if (!HARDCODE.regExpValidaPercentual.test(valor)) {
    
                        valido = false
                    }
    
                } else {

                    if (type === HARDCODE.typeData) {

                        if (typeof valor !== 'object') {

                            valido = false
                        }
                    }
                }
            }
        }

        if (!valido) {
            
            if (XLSXPDF === HARDCODE.XLSX) {

                erros.push(
                    MESSAGE().excelValorInvalido
                        .replace("$linha$", linhaIdentificacao)
                            .replace("$campo$", campo)
                                .replace( "$valor$", valor)
                    )

                } else {

                    erros.push(
                        MESSAGE().pdfValorInvalido
                            .replace("$identificacao$", linhaIdentificacao)
                                .replace("$campo$", campo)
                                    .replace("$valor$", valor)
                    )
                }
        }
    }
}

export const processaValor = (json, campo, valor, camposList, erros, linhaIdentificacao, XLSXPDF) => {

    const valorOriginal = valor

    json[campo] = ''

    if (valor || valor === 0) {

        const linhaCamposList = pesquisaList(camposList, campo)

        if (linhaCamposList.id && !linhaCamposList.array) {

            if (linhaCamposList.list) {

                if (linhaCamposList.autoComplete === "S") {

                    valor = pesquisaAutoCompleteList(linhaCamposList.list, valor)

                } else {

                    if (linhaCamposList.substrList) {

                        valor = pesquisaList(linhaCamposList.list,
                            semAcento(valor.substr(0, linhaCamposList.substrList))).id

                    } else {

                        if (linhaCamposList.pesquisarChave) {

                            valor = pesquisaChaveList(linhaCamposList.list, valor).id

                        } else {

                            valor = pesquisaIdList(linhaCamposList.list, valor)

                            if (!valor) {

                                valor = pesquisaList(linhaCamposList.list, valorOriginal).id
                            }
                        }
                    }
                }

                if (!valor) {

                    if (linhaCamposList.valorNaoEncontrouList) {

                        valor = linhaCamposList.valorNaoEncontrouList

                        json[linhaCamposList.campoNaoEncontrouList] = valorOriginal.toUpperCase().trim()

                    } else {

                        if (XLSXPDF === HARDCODE.XLSX) {

                            erros.push(
                                MESSAGE().excelValorListaInvalido
                                    .replace("$linha$", linhaIdentificacao)
                                        .replace("$campo$", campo)
                                            .replace("$valor$", valorOriginal)
                            )

                        } else {

                            erros.push(
                                MESSAGE().pdfValorListaInvalido
                                    .replace("$identificacao$", linhaIdentificacao)
                                        .replace("$campo$", campo)
                                            .replace("$valor$", valorOriginal)
                            )
                        }
                    }
                }
            }

            if (valor) {

                valor = trataValor(valor, linhaCamposList.type, linhaCamposList.tammax)

                validaValor(campo, valor, linhaCamposList.type, erros, linhaIdentificacao, XLSXPDF)

                json[campo] = valor
            }
        }
    }

    return json
}

export const processarLinhaArquivoArray = (campo, valor, camposList, array, jsonCampo, erros, linhaArquivo, XLSXPDF) => {

    if (valor) {

        const linhaCamposList = pesquisaList(camposList, campo)

        if (linhaCamposList.array && linhaCamposList.array === array) {

            valor = trataValor(valor, linhaCamposList.type, linhaCamposList.tammax)

            validaValor(campo, valor, linhaCamposList.type, erros, linhaArquivo, XLSXPDF)

            jsonCampo.push({...linhaCamposList.init, [linhaCamposList.campoArray]: valor})
        }
    }
}

export const adicionaArraysClienteJson = (json) => {

    return {
        ...json,
        contatos : [],
        enderecos: [],
        receitaMensal: []
    }
}

export const ajustaClienteJson = (json, inicializaVersaoUltimaVersao) => {

    inicializaControleVersao(json, inicializaVersaoUltimaVersao)

    json = inicializaAjustaInclusoesAlteracoesExclusoesJsonInfoclient(null, json, null, null, null, null)

    if (
            json.tipoRegimeBens ||
            json.percHerancaFamiliarDiferenteConjuge ||
            json.nomeConjuge ||
            json.nomeCurtoConjuge ||
            json.cpfConjuge ||
            json.nascimentoConjuge ||
            json.idadeConjuge ||
            json.sexoConjuge ||
            json.grauInstrucaoConjuge ||
            json.formacaoConjuge ||
            json.grauInstrucaoConjuge ||
            json.profissaoConjuge ||
            json.empresaConjuge ||
            json.contatos.filter(contato => contato.familiar === HARDCODE.idFamiliarConjuge).length > 0 ||
            (
                json.contasInvestimentos &&
                json.contasInvestimentos.filter(contaInvestimentos => contaInvestimentos.familiar === HARDCODE.idFamiliarConjuge).length > 0
            )
    ) {
        json.possuiConjuge = true
    }

    if (json.enderecos.length > 0 && !json.contEndereco) {

        json.contEndereco = json.enderecos.length
    }

    if (json.receitaMensal && json.receitaMensal.length > 0) {

        if (!json.contReceitaMensal) {

            json.contReceitaMensal = json.receitaMensal.length
        }

        calculaTotaisReceitaMensal(json)
    }
    
    if (json.recursosFinanceiros && json.recursosFinanceiros.length > 0) {

        calculaTotaisRecursosFinanceiros(json)
    }

    processaCamposCalculados(json)

    return json
}

export const processaErros = (erros, setQtdeErros, setTextDialog, XLSXPDF) => {

    let text = ''
    
    erros?.forEach( (linhaErros, index) => {
                    
        if (text) {

            text += '<br/>'

        } else {

            if (erros.length === 1) {

                text = MESSAGE().importarClienteExcelFormQtdeErro

            } else {

                text = MESSAGE().importarClienteExcelFormQtdeErros.replace("$qtdeErros$", erros.length)
            }

            text += '<br/><br/>'
        }

        text += linhaErros
    })

    if (XLSXPDF === HARDCODE.XLSX) {

        text += '<br/><br/>' + MESSAGE().importarClienteExcelFormOrientacaoErros

    } else {

        // Pendente
        // text += '<br/><br/>' + MESSAGE().importarClientePDFListOrientacaoErros
    }

    setQtdeErros(erros.length)

    setTextDialog(text)
}