import HARDCODE from '../../../business-const/HardCode/HardCode'

import LABEL from '../../../business-const/Label/Label'

import NUMMIN from '../../NumMinMax/NumMin'

import NUMMAX from '../../NumMinMax/NumMax'

import TAMMAX from '../../TamMax/TamMax'

import {
    formataArrayData,
    formataAnoMesInteiroComBarra,
    formataMesDiaInteiroDiaMesComBarra,
} from '../../../business-components/Date/Date'

import {
    formataValor,
    formataCpf,
    formataCelular,
} from '../../../business-components/Formata/Formata'

//import {
//    IconButtonDocument,
//} from '../../../business-components/IconButton/IconButton'

//import {
//    CustomizedDialogsPopup,
//} from '../../../business-components/Dialog/CustomizeDialogs'

import {
    pesquisaDescricaoList,
    incluiCampoList,
} from '../ListService'

import sexoList from '../sexoList'

import tipoFormaPagtoPremioList from '../tipoFormaPagtoPremioList'

import tipoConsorcioList from './tipoConsorcioList'

import administradoraConsorcioList from './administradoraConsorcioList'

import pessoaFisicaJuridicaList from './pessoaFisicaJuridicaList'

import statusGrupoConsorcioList from './statusGrupoConsorcioList'

//import simNaoList from '../simNaoList'



export const camposConsorcioList = ( list, columnsExibe, grafico, filtroList, filtroLists ) => {

    let camposListAux = []

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'identificacaoUsuario',
        label: LABEL.usuario,
        filtro: 'N',
        filtroGrafico: 'N',
        type: null,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'S',
        options: null,
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'S',
        ordem: 10,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'numeroConsorcio',
        label: LABEL.numeroConsorcio,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeString,
        tamMax: TAMMAX.numeroApolice,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'S',
        options: null,
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 20,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'nome',
        label: LABEL.nomeConsorciado,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeStringLike,
        tamMax: TAMMAX.nome,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'S',
        options: null,
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 30,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'cpf',
        label: LABEL.cpf,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeCpf,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'N',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (formataCpf(list[dataIndex].cpf))
            },
        },
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 40,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'nascimento',
        label: LABEL.nascimento,
        filtro: 'N',
        filtroGrafico: 'N',
        type: HARDCODE.typeData,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'N',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (formataArrayData(list[dataIndex].nascimento))
            },
        },
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 50,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'mesDiaNascimento',
        label: LABEL.diaMesNascimento,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeDiaMes,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'N',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (formataMesDiaInteiroDiaMesComBarra(list[dataIndex].mesDiaNascimento))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 60,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'sexo',
        label: LABEL.sexo,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeListString,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: filtroLists.sexoLists,
        columnsDefault: 'N',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (pesquisaDescricaoList(sexoList(true), list[dataIndex].sexo))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 70,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'profissao',
        label: LABEL.profissao,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeListMultiSelectString,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: filtroLists.profissaoLists,
        columnsDefault: 'N',
        options: null,
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 80,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'email',
        label: LABEL.email,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeEmail,
        tamMax: HARDCODE.email,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'N',
        options: null,
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 90,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'celular',
        label: LABEL.celular,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeCelular,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'N',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (formataCelular(list[dataIndex].celular))
            },
        },
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 100,
    })
    
    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'cidadeEnderecoResidencial',
        label: LABEL.cidade,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeListMultiSelectString,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: filtroLists.cidadeEnderecoResidencialLists,
        columnsDefault: 'N',
        options: null,
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 110,
    })
    
    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'administradoraConsorcio',
        label: LABEL.administradoraConsorcio,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeListMultiSelectInteiro,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: filtroLists.administradoraConsorcioLists,
        columnsDefault: 'S',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (pesquisaDescricaoList(administradoraConsorcioList(), list[dataIndex].administradoraConsorcio))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 120,
    })
    
    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'tipoConsorcio',
        label: LABEL.tipoConsorcio,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeListMultiSelectString,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: filtroLists.tipoConsorcioLists,
        columnsDefault: 'S',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (pesquisaDescricaoList(tipoConsorcioList, list[dataIndex].tipoConsorcio))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 125,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'grupoConsorcio',
        label: LABEL.grupoConsorcio,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeStringLike,
        tamMax: TAMMAX.grupoConsorcio,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'S',
        options: null,
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 130,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'cotaConsorcio',
        label: LABEL.cotaConsorcio,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeInteiro,
        tamMax: null,
        numMin: NUMMIN.cotaConsorcio,
        numMax: NUMMAX.cotaConsorcio,
        list: null,
        columnsDefault: 'S',
        options: null,
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 140,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'pessoaFisicaJuridica',
        label: LABEL.pessoaFisicaJuridica,
        filtro: 'N',
        filtroGrafico: 'N',
        type: HARDCODE.typeListString,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: filtroLists.pessoaFisicaJuridicaLists,
        columnsDefault: 'N',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (pesquisaDescricaoList(pessoaFisicaJuridicaList, list[dataIndex].pessoaFisicaJuridica))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 150,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'statusGrupoConsorcio',
        label: LABEL.statusGrupoConsorcio,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeListString,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: filtroLists.statusGrupoConsorcioLists,
        columnsDefault: 'S',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (pesquisaDescricaoList(statusGrupoConsorcioList, list[dataIndex].statusGrupoConsorcio))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 160,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'dataAssembleia',
        label: LABEL.dataAssembleia,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeData,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'S',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (formataArrayData(list[dataIndex].dataAssembleia))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 160,
    })
    
    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'anoMesAssembleia',
        label: LABEL.anoMesAssembleia,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeInteiro,
        tamMax: null,
        numMin: NUMMIN.anoMes,
        numMax: NUMMAX.anoMes,
        list: null,
        columnsDefault: 'N',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (formataAnoMesInteiroComBarra(list[dataIndex].anoMesAssembleia))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 170,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'numeroAssembleiaParticipacao',
        label: LABEL.numeroAssembleiaParticipacao,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeInteiro,
        tamMax: null,
        numMin: NUMMIN.numeroAssembleiaParticipacao,
        numMax: NUMMAX.numeroAssembleiaParticipacao,
        list: null,
        columnsDefault: 'S',
        options: null,
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 180,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'valorCreditoConsorcio',
        label: LABEL.valorCreditoConsorcio,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeValor,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'S',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (formataValor(list[dataIndex].valorCreditoConsorcio, 2))
            },
        },
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 190,
    })
    
    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'valorParcelaInicial',
        label: LABEL.valorParcelaInicial,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeValor,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'S',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (formataValor(list[dataIndex].valorParcelaInicial, 2))
            },
        },
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 200,
    })
    
    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'tipoFormaPagtoConsorcio',
        label: LABEL.tipoFormaPagto,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeListMultiSelectString,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: filtroLists.tipoFormaPagtoPremioLists,
        columnsDefault: 'S',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (pesquisaDescricaoList(tipoFormaPagtoPremioList, list[dataIndex].tipoFormaPagtoConsorcio))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 210,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'dataVenctoBoleto',
        label: LABEL.dataVenctoBoleto,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeData,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'S',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (formataArrayData(list[dataIndex].dataVenctoBoleto))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 220,
    })
    
    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'anoMesVenctoBoleto',
        label: LABEL.anoMesVenctoBoleto,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeInteiro,
        tamMax: null,
        numMin: NUMMIN.anoMes,
        numMax: NUMMAX.anoMes,
        list: null,
        columnsDefault: 'N',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (formataAnoMesInteiroComBarra(list[dataIndex].anoMesVenctoBoleto))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 230,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'prazoTotalGrupoConsorcio',
        label: LABEL.prazoTotalGrupoConsorcio,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeInteiro,
        tamMax: null,
        numMin: NUMMIN.prazoTotalGrupoConsorcio,
        numMax: NUMMAX.prazoTotalGrupoConsorcio,
        list: null,
        columnsDefault: 'S',
        options: null,
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 240,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'numeroTotalParticipantesGrupoConsorcio',
        label: LABEL.numeroTotalParticipantesGrupoConsorcio,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeInteiro,
        tamMax: null,
        numMin: NUMMIN.numeroTotalParticipantesGrupoConsorcio,
        numMax: NUMMAX.numeroTotalParticipantesGrupoConsorcio,
        list: null,
        columnsDefault: 'S',
        options: null,
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 250,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'taxaAdministracaoTotal',
        label: LABEL.taxaAdministracaoTotal,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typePercentual,
        tamMax: null,
        numMin: NUMMIN.perc,
        numMax: NUMMAX.perc,
        list: null,
        columnsDefault: 'S',
        options: null,
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 260,
    })
    
    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'percFundoReserva',
        label: LABEL.percFundoReserva,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typePercentual,
        tamMax: null,
        numMin: NUMMIN.perc,
        numMax: NUMMAX.perc,
        list: null,
        columnsDefault: 'S',
        options: null,
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 270,
    })
    
    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'percSeguroVida',
        label: LABEL.percSeguroVida,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typePercentual,
        tamMax: null,
        numMin: NUMMIN.perc,
        numMax: NUMMAX.perc,
        list: null,
        columnsDefault: 'S',
        options: null,
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 280,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'percLanceEmbutido',
        label: LABEL.percLanceEmbutido,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typePercentual,
        tamMax: null,
        numMin: NUMMIN.perc,
        numMax: NUMMAX.perc,
        list: null,
        columnsDefault: 'S',
        options: null,
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 290,
    })
   
    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'percLanceFixo',
        label: LABEL.percLanceFixo,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typePercentual,
        tamMax: null,
        numMin: NUMMIN.perc,
        numMax: NUMMAX.perc,
        list: null,
        columnsDefault: 'S',
        options: null,
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 300,
    })

    /*
    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'producaoPropria',
        label: LABEL.producaoPropria,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeListString,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: simNaoList,
        columnsDefault: 'N',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (pesquisaDescricaoList(simNaoList, list[dataIndex].producaoPropria))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 310,
    })
    */

    /*
    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'links',
        label: LABEL.links,
        filtro: 'N',
        filtroGrafico: 'N',
        type: null,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'S',
        options: {
            customBodyRenderLite: (dataIndex) => (
                <div
                    className="flex items-center"
                >
                    <CustomizedDialogsPopup
                        maxWidth="lg"
                        popupTitle={LABEL.extratoApolice}
                        tooltipTitle={LABEL.extratoApolice}
                        PopupButton={IconButtonDocument}
                        popupComponent={
                            <PopupComponent/>
                        }
                    />
                </div>
            ),
        },
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 320,
    })
    */

    return camposListAux
}

export default camposConsorcioList