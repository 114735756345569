import React from 'react'

import { 
    IsXsDownScreen
} from '../../business-components/Service/GlobalService'

import {
    Grid,
} from '@material-ui/core'

import { 
    IsSmDownScreen,
} from '../../business-components/Service/GlobalService'



const classNameGridContainer = "mb-0"



export const GridContainer = props => {
    return (
        <Grid container spacing={1} className={classNameGridContainer}
            {...props}
        />
    )
}

export const GridContainerS0 = props => {
    return (
        <Grid container spacing={0} className={classNameGridContainer}
            {...props}
        />
    )
}

export const GridContainerS3 = props => {

    const { className, ...rest } = props

    return (
        <Grid container spacing={3} className={classNameGridContainer + (className ? ' ' + className : '')}
            {...rest}
        />
    )
}

export const GridContainerLogin = props => {
    return (
        <Grid container spacing={1}
            {...props}
        />
    )
}

export const GridContainerS3smDown2 = props => {
    return (
        <Grid container spacing={IsSmDownScreen ? 2 : 3} className={classNameGridContainer}
            {...props}
        />
    )
}

export const GridContainerGridItemButtonIncluirLinha = props => {
    return (
        <GridContainer>
            <GridItem11xs12
                {...props}
            />
        </GridContainer>
    )
}

export const GridContainerReport = props => {
    return (
        <Grid container
            {...props}
        />
    )
}

export const GridContainerReportResumo = props => {
    return (
        <Grid container spacing={2}
            {...props}
        />
    )
}





export const GridItem1 = props => {
    return (
        <Grid item lg={1} md={1} sm={1} xs={1}
            {...props}
        />  
    )
}

export const GridItem1xs2 = props => {
    return (
        <Grid item lg={1} md={1} sm={1} xs={2}
            {...props}
        />  
    )
}

export const GridItem1sm2xs2 = props => {
    return (
        <Grid item lg={1} md={1} sm={2} xs={2}
            {...props}
        />  
    )
}

export const GridItem1sm3xs3 = props => {
    return (
        <Grid item lg={1} md={1} sm={3} xs={3}
            {...props}
        />  
    )
}

export const GridItem1xs6 = props => {
    return (
        <Grid item lg={1} md={1} sm={1} xs={6}
            {...props}
        />  
    )
}



export const GridItem2 = props => {
    return (
        <Grid item lg={2} md={2} sm={2} xs={2}
            {...props}
        />  
    )
}

export const GridItem2xs1 = props => {
    return (
        <Grid item lg={2} md={2} sm={2} xs={1}
            {...props}
        />  
    )
}

export const GridItem2xs4 = props => {
    return (
        <Grid item lg={2} md={2} sm={2} xs={4}
            {...props}
        />  
    )
}

export const GridItem2xs6 = props => {
    return (
        <Grid item lg={2} md={2} sm={2} xs={6}
            {...props}
        />  
    )
}

export const GridItem2xs6NotIsXsDownScreen = () => {

    return (
        <GridItem2xs6
            style={{
                display: 
                    !IsXsDownScreen()
                        ? "block"
                        : "none"
            }} 
        />
    )
}

export const GridItem2xs10 = props => {
    return (
        <Grid item lg={2} md={2} sm={2} xs={10}
            {...props}
        />  
    )
}

export const GridItem2xs10NotIsXsDownScreen = () => {

    return (
        <GridItem2xs10
            style={{
                display: 
                    !IsXsDownScreen()
                        ? "block"
                        : "none"
            }} 
        />
    )
}

export const GridItem2sm3xs6 = props => {
    return (
        <Grid item lg={2} md={2} sm={3} xs={6}
            {...props}
        />  
    )
}

export const GridItem2sm4xs6 = props => {
    return (
        <Grid item lg={2} md={2} sm={4} xs={6}
            {...props}
        />  
    )
}

export const GridItem2sm6xs6 = props => {
    return (
        <Grid item lg={2} md={2} sm={6} xs={6}
            {...props}
        />  
    )
}

export const GridItem2xs12 = props => {
    return (
        <Grid item lg={2} md={2} sm={2} xs={12}
            {...props}
        />  
    )
}



export const GridItem3 = props => {
    return (
        <Grid item lg={3} md={3} sm={3} xs={3}
            {...props}
        />  
    )
}

export const GridItem3xs1 = props => {
    return (
        <Grid item lg={3} md={3} sm={3} xs={1}
            {...props}
        />  
    )
}

export const GridItem3xs2 = props => {
    return (
        <Grid item lg={3} md={3} sm={3} xs={2}
            {...props}
        />  
    )
}

export const GridItem3xs4 = props => {
    return (
        <Grid item lg={3} md={3} sm={3} xs={4}
            {...props}
        />  
    )
}

export const GridItem3xs5 = props => {
    return (
        <Grid item lg={3} md={3} sm={3} xs={5}
            {...props}
        />  
    )
}

export const GridItem3xs6 = props => {
    return (
        <Grid item lg={3} md={3} sm={3} xs={6}
            {...props}
        />  
    )
}

export const GridItem3xs6NotIsXsDownScreen = () => {

    return (
        <GridItem3xs6
            style={{
                display: 
                    !IsXsDownScreen()
                        ? "block"
                        : "none"
            }} 
        />
    )
}

export const GridItem3xs10 = props => {
    return (
        <Grid item lg={3} md={3} sm={3} xs={10}
            {...props}
        />  
    )
}

export const GridItem3xs10NotIsXsDownScreen = () => {

    return (
        <GridItem3xs10
            style={{
                display: 
                    !IsXsDownScreen()
                        ? "block"
                        : "none"
            }} 
        />
    )
}

export const GridItem3xs12 = props => {
    return (
        <Grid item lg={3} md={3} sm={3} xs={12}
            {...props}
        />  
    )
}

export const GridItem3sm4xs10 = props => {
    return (
        <Grid item lg={3} md={3} sm={4} xs={10}
            {...props}
        />  
    )
}

export const GridItem3sm6xs6 = props => {
    return (
        <Grid item lg={3} md={3} sm={6} xs={6}
            {...props}
        />  
    )
}



export const GridItem4 = props => {
    return (
        <Grid item lg={4} md={4} sm={4} xs={4}
            {...props}
        />  
    )
}

export const GridItem4xs1 = props => {
    return (
        <Grid item lg={4} md={4} sm={4} xs={1}
            {...props}
        />  
    )
}

export const GridItem4xs5 = props => {
    return (
        <Grid item lg={4} md={4} sm={4} xs={5}
            {...props}
        />  
    )
}

export const GridItem4xs6 = props => {
    return (
        <Grid item lg={4} md={4} sm={4} xs={6}
            {...props}
        />  
    )
}

export const GridItem4xs7 = props => {
    return (
        <Grid item lg={4} md={4} sm={4} xs={7}
            {...props}
        />  
    )
}

export const GridItem4xs8 = props => {
    return (
        <Grid item lg={4} md={4} sm={4} xs={8}
            {...props}
        />  
    )
}

export const GridItem4xs9 = props => {
    return (
        <Grid item lg={4} md={4} sm={4} xs={9}
            {...props}
        />  
    )
}

export const GridItem4xs10 = props => {
    return (
        <Grid item lg={4} md={4} sm={4} xs={10}
            {...props}
        />  
    )
}

export const GridItem4xs10NotIsXsDownScreen = () => {

    return (
        <GridItem4xs10
            style={{
                display: 
                    !IsXsDownScreen()
                        ? "block"
                        : "none"
            }} 
        />
    )
}

export const GridItem4xs12 = props => {
    return (
        <Grid item lg={4} md={4} sm={4} xs={12}
            {...props}
        />  
    )
}

export const GridItem4sm6xs6 = props => {
    return (
        <Grid item lg={4} md={4} sm={6} xs={6}
            {...props}
        />  
    )
}
export const GridItem4sm6xs12 = props => {
    return (
        <Grid item lg={4} md={4} sm={6} xs={12}
            {...props}
        />  
    )
}

export const GridItem4sm12xs12 = props => {
    return (
        <Grid item lg={4} md={4} sm={12} xs={12}
            {...props}
        />  
    )
}




export const GridItem5 = props => {
    return (
        <Grid item lg={5} md={5} sm={5} xs={5}
            {...props}
        />  
    )
}

export const GridItem5xs1 = props => {
    return (
        <Grid item lg={5} md={5} sm={5} xs={1}
            {...props}
        />  
    )
}

export const GridItem5xs6 = props => {
    return (
        <Grid item lg={5} md={5} sm={5} xs={6}
            {...props}
        />  
    )
}

export const GridItem5xs7 = props => {
    return (
        <Grid item lg={5} md={5} sm={5} xs={7}
            {...props}
        />  
    )
}

export const GridItem5xs10 = props => {
    return (
        <Grid item lg={5} md={5} sm={5} xs={10}
            {...props}
        />  
    )
}

export const GridItem5xs12 = props => {
    return (
        <Grid item lg={5} md={5} sm={5} xs={12}
            {...props}
        />  
    )
}

export const GridItem5sm12xs12 = props => {
    return (
        <Grid item lg={5} md={5} sm={12} xs={12}
            {...props}
        />  
    )
}





export const GridItem6 = props => {
    return (
        <Grid item lg={6} md={6} sm={6} xs={6}
            {...props}
        />  
    )
}

export const GridItem6xs1 = props => {
    return (
        <Grid item lg={6} md={6} sm={6} xs={1}
            {...props}
        />  
    )
}

export const GridItem6xs4 = props => {
    return (
        <Grid item lg={6} md={6} sm={6} xs={4}
            {...props}
        />  
    )
}

export const GridItem6xs7 = props => {
    return (
        <Grid item lg={6} md={6} sm={6} xs={7}
            {...props}
        />  
    )
}

export const GridItem6xs8 = props => {
    return (
        <Grid item lg={6} md={6} sm={6} xs={8}
            {...props}
        />  
    )
}

export const GridItem6xs9 = props => {
    return (
        <Grid item lg={6} md={6} sm={6} xs={9}
            {...props}
        />  
    )
}

export const GridItem6xs10 = props => {
    return (
        <Grid item lg={6} md={6} sm={6} xs={10}
            {...props}
        />  
    )
}

export const GridItem6xs12 = props => {
    return (
        <Grid item lg={6} md={6} sm={6} xs={12}
            {...props}
        />  
    )
}

export const GridItem6sm3xs6 = props => {
    return (
        <Grid item lg={6} md={6} sm={3} xs={6}
            {...props}
        />  
    )
}

export const GridItem6sm9xs12 = props => {
    return (
        <Grid item lg={6} md={6} sm={9} xs={12}
            {...props}
        />  
    )
}

export const GridItem6sm12xs12 = props => {
    return (
        <Grid item lg={6} md={6} sm={12} xs={12}
            {...props}
        />  
    )
}

export const GridItem6md12sm12xs12 = props => {
    return (
        <Grid item lg={6} md={12} sm={12} xs={12}
            {...props}
        />  
    )
}





export const GridItem7 = props => {
    return (
        <Grid item lg={7} md={7} sm={7} xs={7}
            {...props}
        />  
    )
}

export const GridItem7xs10 = props => {
    return (
        <Grid item lg={7} md={7} sm={7} xs={10}
            {...props}
        />  
    )
}

export const GridItem7xs10NotIsXsDownScreen = () => {

    return (
        <GridItem7xs10
            style={{
                display: 
                    !IsXsDownScreen()
                        ? "block"
                        : "none"
            }} 
        />
    )
}

export const GridItem7xs12 = props => {
    return (
        <Grid item lg={7} md={7} sm={7} xs={12}
            {...props}
        />  
    )
}

export const GridItem7sm10xs10 = props => {
    return (
        <Grid item lg={7} md={7} sm={10} xs={10}
            {...props}
        />  
    )
}

export const GridItem7sm12xs12 = props => {
    return (
        <Grid item lg={7} md={7} sm={12} xs={12}
            {...props}
        />  
    )
}





export const GridItem8 = props => {
    return (
        <Grid item lg={8} md={8} sm={8} xs={8}
            {...props}
        />  
    )
}

export const GridItem8xs7 = props => {
    return (
        <Grid item lg={8} md={8} sm={8} xs={7}
            {...props}
        />  
    )
}

export const GridItem8xs10 = props => {
    return (
        <Grid item lg={8} md={8} sm={8} xs={10}
            {...props}
        />  
    )
}

export const GridItem8xs12 = props => {
    return (
        <Grid item lg={8} md={8} sm={8} xs={12}
            {...props}
        />  
    )
}

export const GridItem8sm12xs12 = props => {
    return (
        <Grid item lg={8} md={8} sm={12} xs={12}
            {...props}
        />  
    )
}

export const GridItem8md10sm12xs12 = props => {
    return (
        <Grid item lg={8} md={10} sm={12} xs={12}
            {...props}
        />  
    )
}





export const GridItem9 = props => {
    return (
        <Grid item lg={9} md={9} sm={9} xs={9}
            {...props}
        />  
    )
}

export const GridItem9xs5 = props => {
    return (
        <Grid item lg={9} md={9} sm={9} xs={5}
            {...props}
        />  
    )
}

export const GridItem9xs6 = props => {
    return (
        <Grid item lg={9} md={9} sm={9} xs={6}
            {...props}
        />  
    )
}

export const GridItem9xs10 = props => {
    return (
        <Grid item lg={9} md={9} sm={9} xs={10}
            {...props}
        />  
    )
}

export const GridItem9sm12xs12 = props => {
    return (
        <Grid item lg={9} md={9} sm={12} xs={12}
            {...props}
        />  
    )
}





export const GridItem10 = props => {
    return (
        <Grid item lg={10} md={10} sm={10} xs={10}
            {...props}
        />  
    )
}

export const GridItem10xs8 = props => {
    return (
        <Grid item lg={10} md={10} sm={10} xs={8}
            {...props}
        />  
    )
}

export const GridItem10xs11 = props => {
    return (
        <Grid item lg={10} md={10} sm={10} xs={11}
            {...props}
        />  
    )
}

export const GridItem10xs12 = props => {
    return (
        <Grid item lg={10} md={10} sm={10} xs={12}
            {...props}
        />  
    )
}

export const GridItem10sm12xs12 = props => {
    return (
        <Grid item lg={10} md={10} sm={12} xs={12}
            {...props}
        />  
    )
}





export const GridItem11 = props => {
    return (
        <Grid item lg={11} md={11} sm={11} xs={11}
            {...props}
        />  
    )
}

export const GridItem11xs6 = props => {
    return (
        <Grid item lg={11} md={11} sm={11} xs={6}
            {...props}
        />  
    )
}

export const GridItem11sm10xs10 = props => {
    return (
        <Grid item lg={11} md={11} sm={10} xs={10}
            {...props}
        />  
    )
}

export const GridItem11xs10 = props => {
    return (
        <Grid item lg={11} md={11} sm={11} xs={10}
            {...props}
        />  
    )
}

export const GridItem11xs12 = props => {
    return (
        <Grid item lg={11} md={11} sm={11} xs={12}
            {...props}
        />  
    )
}



export const GridItem12 = props => {
    return (
        <Grid item lg={12} md={12} sm={12} xs={12}
            {...props}
        />  
    )
}

export const GridItem12mt12IsXsDownScreen = () => {

    return (
        <GridItem12
            className='mt-12'
            style={{
                display: 
                    IsXsDownScreen()
                        ? "block"
                        : "none"
            }} 
        />
    )
}